import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GixamPrivacyPolicyComponent } from 'src/app/gixam-privacy-policy/gixam-privacy-policy.component';

@Component({
  selector: 'gixam-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  
  constructor(private dialog:MatDialog) {      
  }

  openPrivacyPolicy(){
    this.dialog.open(GixamPrivacyPolicyComponent);
  }
}
