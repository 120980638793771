import { AfterContentChecked, AfterContentInit, AfterViewChecked, AfterViewInit, Component, Inject, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelect, matSelectAnimations } from '@angular/material/select';
import { switchMap, of, map, shareReplay } from 'rxjs';
import { GixamCountryModel, GixamStateModel } from 'src/app/models/gixam-country.model';
import { GixamGendersEnum } from 'src/app/models/enums/gixam-genders.enum';
import { GixamPatientModel, GixamPatientUpdateModel } from 'src/app/models/gixam-patient.model';
import { GixamCountriesDataService } from 'src/app/services/gixam-countries-data.service';
import { GixamPatientsDataService } from 'src/app/services/gixam-patients-data.service';
import { phoneNumberValidator } from 'src/app/validators/phone-number-validator';

@Component({
  selector: 'gixam-edit-study-contact-details-dialog',
  templateUrl: './gixam-edit-study-contact-details-dialog.component.html',
  styleUrls: ['./gixam-edit-study-contact-details-dialog.component.scss']
})
export class GixamEditStudyContactDetailsDialogComponent implements AfterViewInit {
  @ViewChild('countryCodeSelect') countryCodeSelect: MatSelect;

  GixamGender: GixamGendersEnum;
  gixamGenders = GixamGendersEnum.listAll();
  countries_list$ = this.countriesDataService.getAllCountries();

  editPatientForm = new FormGroup({
    id: new FormControl<string | null>({ value: null, disabled: true }, [Validators.required]),
    firstName: new FormControl<string | null>(null),
    lastName: new FormControl<string | null>(null),
    email: new FormControl<string | null>(null, [Validators.email]),
    phone: new FormGroup({
      countryCode: new FormControl<GixamCountryModel | null>(null),
      phoneNumber: new FormControl<string | null>(null),
    }, { updateOn: 'change', validators: phoneNumberValidator.bind(this) }),
    // phoneNumber: new FormControl<string | null>(null),
    // countryCode: new FormControl<GixamCountryModel | null>(null),
    patientId: new FormControl<string | null>({ value: null, disabled: true }, [Validators.required])
    // street: new FormControl<string | null>(null),
    // city: new FormControl<string | null>(null),
    // state: new FormControl<string | null>({ value: null, disabled: true }),
    // country: new FormControl<string | null>(""),
    // postalCode: new FormControl<string | null>(null),
  });

  // states_list$ = this.editPatientForm.get('country')!.valueChanges
  //   .pipe(
  //     switchMap(country => {
  //       if (country && country == 'US')
  //         return this.countriesDataService.getStatesByCountryCode(country);
  //       else
  //         return of(null);
  //     }),
  //     map((states: any) => {
  //       if (states && states.length) {
  //         this.editPatientForm.get('state')!.enable();
  //         return states;
  //       }
  //       else {
  //         this.editPatientForm.get('state')!.disable();
  //         this.editPatientForm.get('state')!.setValue(null);
  //         return null;
  //       }
  //     }), shareReplay(1)
  //   );

  constructor(@Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<GixamEditStudyContactDetailsDialogComponent>,
    private patientsDataService: GixamPatientsDataService,
    private countriesDataService: GixamCountriesDataService) { }

  ngAfterViewInit() {
    if (this.data && this.data.patient)
      this.setPatient(this.data.patient);
  }

  setPatient(gxmPatient: any) {

    this.editPatientForm.patchValue({
      id: gxmPatient.id,
      firstName: gxmPatient.firstName,
      lastName: gxmPatient.lastName,
      email: gxmPatient.email,
      phone: {
        phoneNumber: gxmPatient.phoneNumber,          
      },
      patientId: gxmPatient.number
      // street: gxmPatient.street,
      // city: gxmPatient.city,
      // state: gxmPatient.state,
      // country: gxmPatient.country,
      // postalCode: gxmPatient.postalCode,
    });

    setTimeout(() => {      
      if (gxmPatient.countryCode) {
        let optionsArray = this.countryCodeSelect.options.toArray();

        let countryObj = optionsArray.find(element=> {return element.value.countryCode == gxmPatient.countryCode});
                  
        this.editPatientForm.get('phone')?.get('countryCode')?.patchValue(countryObj?.value);
      }     
    },0);



  }

  submit() {
    if (!this.editPatientForm.valid) {
      return;
    }

    var model = this.generateSubmitModel();

    this.patientsDataService.updatePatient(model)
      .subscribe(result => {
        if (result) {
          this.dialogRef.close(model);
        }
      })
  }

  private generateSubmitModel(): GixamPatientUpdateModel {

    let formValues = this.editPatientForm.getRawValue();

    let patientUpdateModel = new GixamPatientUpdateModel();


    patientUpdateModel.id = formValues.id!;
    patientUpdateModel.firstName = formValues.firstName;
    patientUpdateModel.lastName = formValues.lastName;
    patientUpdateModel.email = formValues.email;
    patientUpdateModel.countryCode = formValues.phone?.countryCode?.countryCode || null;
    patientUpdateModel.phoneNumber = formValues.phone.phoneNumber;
    // patientUpdateModel.street = formValues.street;
    // patientUpdateModel.city = formValues.city;
    // patientUpdateModel.state = formValues.state || null;
    // patientUpdateModel.country = formValues.country || null;
    // patientUpdateModel.postalCode = formValues.postalCode;
    return patientUpdateModel;
  }

  countryCompare(c1: string, c2: string): boolean {
    return c1 === c2;
  }

  stateCompare(c1: string, c2: string): boolean {
    return c1 === c2;
  }

  clearField(event:MouseEvent, element:any){
    
    event.stopPropagation();
    
    if (element && element.ngControl){
      element.ngControl.control.patchValue(null);
    }

  }
}
