import { Component } from '@angular/core';

@Component({
  selector: 'app-gixam-privacy-policy',
  templateUrl: './gixam-privacy-policy.component.html',
  styleUrls: ['./gixam-privacy-policy.component.scss']
})
export class GixamPrivacyPolicyComponent {

}
