import { HttpClient} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, switchMap, map, catchError, throwError, shareReplay, Subject, tap } from 'rxjs';
import { LoginRequestModel } from '../models/LoginRequest.model';
import { JubaanStorageService } from '../services/jubaan-storage.service';
import { environment } from 'src/environments/environment';
import { LoginResponseModel } from '../models/Response/gixam-login-response.model';
import { GixamRegistrationStepsEnum } from '../models/enums/gixam-registration-steps.enum';
import { UserRegistrationModel } from '../models/UserRegistration.model';
import { GixamAccountDataService } from '../services/gixam-account-data.service';
import { GixamForgotPasswordModel } from '../models/GixamForgotPassword.model';
import { GixamResetPasswordModel } from '../models/GixamResetPasswordModel';
import { UserManager, User, UserManagerSettings, WebStorageStateStore } from 'oidc-client-ts';
import { Router } from '@angular/router';
import { GixamUserModel } from '../models/gixam-user.model';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  private endpointPath = of(environment.apiUrl + 'api/users');
  private _userManager: UserManager;
  private _user: User | null;
  private _loginChangedSubject = new Subject<boolean>();
  public loginChanged = this._loginChangedSubject.asObservable();
  
  // public currentClinic :string|null = null;

  redirectUrl: string | null = null;

  constructor(private httpClient: HttpClient,
    private accountDataService: GixamAccountDataService,    
    private storageService: JubaanStorageService) {

    this._userManager = new UserManager(this.idpSettings);

    this._userManager.getUser().then(u => {
      this._user = u;
    });

    this._userManager.events.addAccessTokenExpired(_ => {

      this._loginChangedSubject.next(false);

      // this.router.navigate(['site','home'],{replaceUrl:true});

    });

  }

  private get idpSettings(): UserManagerSettings {
    return {
      authority: environment.idpAuthority,
      client_id: environment.clientId,
      redirect_uri: `${environment.clientRoot}/account/signin-callback`,
      scope: 'openid email GixamAPI',
      response_type: 'code',      
      silent_redirect_uri: `${environment.clientRoot}/account/silent-callback`,
      automaticSilentRenew: false,
      revokeTokensOnSignout: true,
      userStore: new WebStorageStateStore({ store: sessionStorage }),
    }
  }

  public isUserAuthenticated = (): Promise<boolean> => {  

    return this._userManager.getUser()
    .then(user => {
      if(this._user !== user){
        this._loginChangedSubject.next(this.checkUser(user));
      }

      this._user = user;

      return this.checkUser(user);
    })    
  }

  private checkUser = (user: User | null): boolean => {
    return !!user && !user.expired;
  }

  public login = () => this._userManager.signinRedirect({ state: 15 });

  public signup = () => location.href = `${environment.idpAuthority}/account/register?appId=${environment.clientId}`;

  public signout = () => {

    var signoutSettings = {
      id_token_hint: this._user?.id_token,
      post_logout_redirect_uri: `${environment.clientRoot}`
    }

    this._userManager.signoutRedirect(signoutSettings);
  }

  public finishLogin = () => {
    return this._userManager.signinCallback()
      .then(user => {
        if (user) {
          this._userManager.storeUser(user);
          
          this._user = user;          
          this._loginChangedSubject.next(this.checkUser(user));
          return this.checkUser(user);
        }
        return false;
      }).catch(err => {        
        // this._userManager.clearStaleState();      
      });
  }

  public finishSignout = () => {
    this._user = null;
    this._userManager.clearStaleState();
    this._loginChangedSubject.next(false);
    return this._userManager.signoutCallback(`${environment.clientRoot}`,true);

  }

  public finishSilent = () => {
    return this._userManager.signinSilentCallback();
  }

  public getAccessToken = (): Promise<string | null> => {
    return this._userManager.getUser()
      .then(user => {
        return !!user && !user.expired ? user.access_token : null;
      })
  }

  public performLogin(model: LoginRequestModel): Observable<GixamRegistrationStepsEnum> {


    return this.accountDataService.login(model)
      .pipe(map((loginResult: LoginResponseModel) => {

        if (loginResult.apiToken) {
          this.storageService.setUserName(loginResult.name);
          this.storageService.setAuthorizationData(JSON.stringify(loginResult));
          this.storageService.setCredentialsData(loginResult.apiToken);
        }

        return loginResult.registrationStep;
      }),
        shareReplay(),
        catchError(err => throwError(() => new Error(err))));
  }

  public Register(model: UserRegistrationModel): Observable<string> {
    return this.endpointPath.pipe(
      switchMap(endpointBase => this.httpClient.post<string>(endpointBase + '/registration', model)));
  }

  public confirmEmail(email: string, token: string): Observable<boolean> {
    return this.endpointPath.pipe(
      switchMap(endpointBase => this.httpClient.get<boolean>(endpointBase + '/email/verify',
        {
          params:
          {
            email: email,
            token: token
          }
        })));

  }

  public sendEmailConfirmation(email: string): Observable<boolean> {
    return this.endpointPath.pipe(
      switchMap(endpointBase => this.httpClient.get<boolean>(endpointBase + '/email/confirmation', { params: { email: email } })));
  }


  public createApplicationUser() {

    var applicationId = environment.clientId;
    var memberId = this._user?.profile.sub;

    var model = {
      applicationId: applicationId,
      languageId: null,
      memberId: memberId
    }
    return this.accountDataService.createUser(model);
  }

  public forgotPassword(model: GixamForgotPasswordModel): Observable<boolean> {
    return this.endpointPath.pipe(
      switchMap(endpointBase => this.httpClient.post<boolean>(endpointBase + '/forgot-password', model)));
  }

  public resetPassword(model: GixamResetPasswordModel): Observable<boolean> {
    return this.endpointPath.pipe(
      switchMap(endpointBase => this.httpClient.post<boolean>(endpointBase + '/reset-password', model)));
  }

  public logout() {
    return this.endpointPath.pipe(
      switchMap(endpointBase => this.httpClient.post<boolean>(endpointBase + '/logout', {})));
  }

  public getApplicationUser() {

    var memberId = this._user!.profile!.sub;

    return this.accountDataService.getUser(memberId)
      .pipe(map((user) => user.applicationUser));
  }


  public postLogin(){

    let memberId = this._user!.profile.sub;

    return this.accountDataService.processPostLogin(memberId);
  }


  public getInvitationUser(userEmail:string){
    return this.accountDataService.getInvitationUser(userEmail)
    .pipe(tap((invitationUser)=>console.log(invitationUser)));
  }

  public getAuthorizationHeaderValue() {
    return `Bearer ${this._user?.access_token}`;
  }

  get userName(): string | null {
    var token = this._user?.access_token;

    let payload;

    if (token) {

      payload = token.split(".")[1];

      payload = window.atob(payload);
      try{
        var tokenData = JSON.parse(payload);

        if (!tokenData)
          return null;

        return tokenData.name
      }
      catch(e){
        return null;
      }
    }

    return null;
  }

  get email(): string | null {
    var token = this._user?.access_token;

    let payload;

    if (token) {

      payload = token.split(".")[1];

      payload = window.atob(payload);
      try{
        var tokenData = JSON.parse(payload);

        if (!tokenData)
          return null;

        return tokenData.email
      }
      catch(e){
        return null;
      }
    }

    return null;
  }

  setCurrentClinic(clinicId:string){
    sessionStorage.setItem('currentClinic',clinicId);
  }

  get currentClinic(){
    return sessionStorage.getItem('currentClinic');
  }
}
