<ng-container *ngIf="{
  searchOptions: searchOptions$ | async,
  pageRowsCount: pageRowsCount$ | async,
  totalRowsCount : totalRowsCount$ | async } as data">
  <div style="position: relative;">
    <mat-table [dataSource]="productsTableDataSource" matSort (matSortChange)="productSortChanged($event)">

      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{'PRODUCT_NAME' | translate}}</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <div style="display: flex;flex-direction: row;gap:1rem;align-items:center">
            <div class="cell-inner" style="display: flex;flex-direction: column;">
              <span>{{element.name}}</span>
            </div>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="description">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{'DESCRIPTION' | translate}}</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.description}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="generation">
        <mat-header-cell *matHeaderCellDef>{{'GENERATION' | translate}}</mat-header-cell>
        <mat-cell *matCellDef="let element">{{element.generation}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="model">
        <mat-header-cell *matHeaderCellDef>{{'MODEL' | translate}}</mat-header-cell>
        <mat-cell *matCellDef="let element">{{element.model}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef style="flex:0 0 80px"></mat-header-cell>
        <mat-cell *matCellDef="let element" style="flex:0 0 80px">

          <button mat-icon-button color="primary" title="Product Details"
            (click)="productDetailsDialog($event,element)">
            <mat-icon class="material-icons-outlined">remove_red_eye</mat-icon>
          </button>
        </mat-cell>
      </ng-container>


      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="productDetailsDialog($event,row)"></mat-row>

      <tr style="width: 100%;display: flex;" *matNoDataRow>
        <td class="mat-cell" colspan="6"
          style="margin-top:2rem; border:1px solid #e9e9e9;border-radius:7px;height: 200px; padding: 1rem;display: flex;flex-direction: row;align-items: center;justify-content: center;width: 100%;font-size: 1.5rem;">
          {{'EMPTY_TABLE_RESULTS_NO_{PRODUCTS}_FOUND.' | translate}}
        </td>
        
    </mat-table>
    <ngx-ui-loader [loaderId]="'products-loader'" [hasProgressBar]="false"></ngx-ui-loader>
    <mat-paginator (page)="pageChange($event)" [length]="data?.totalRowsCount"
      [pageSize]="data?.searchOptions?.pageSize" [pageIndex]="data?.searchOptions?.pageNumber"
      *ngIf="data?.totalRowsCount! > 0">
    </mat-paginator>
  </div>
</ng-container>