import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class GixamShoppingCartService {
  private cartItemsSubject = new BehaviorSubject<any[]>([]);
  cartItems$ = this.cartItemsSubject.asObservable();

  private totalPriceSubject = new BehaviorSubject<number>(0);
  totalPrice$: Observable<number> = this.totalPriceSubject.asObservable();

  constructor() {
    this.cartItems$
      .pipe(map((items) => this.calculateTotalPrice(items)))
      .subscribe((totalPrice) => this.totalPriceSubject.next(totalPrice));
  }

  private calculateTotalPrice(items: any[]): number {
    return items.reduce((total, item) => total + (200) * (item.amount || 1), 0);

    // return items.reduce((total, item) => total + (item.price || 0) * (item.amount || 1), 0);
  }

  addItem(newItems: any[]): void {
    let currentItems = this.cartItemsSubject.value;

    newItems.forEach((newItem) => {
      const existingItemIndex = currentItems.findIndex(
        item => item.productId === newItem.productId && item.device === newItem.device
      );

      if (existingItemIndex > -1) {
        currentItems[existingItemIndex].amount = (currentItems[existingItemIndex].amount || 1) + 1;
      } else {
        newItem.amount = 1;
        currentItems.push(newItem);
      }
    });

    this.cartItemsSubject.next([...currentItems]);
  }

  removeItem(item: any): void {
    const updatedItems = this.cartItemsSubject.getValue().filter(
      i => item.productId !== i.productId || item.device !== i.device
    );
    this.cartItemsSubject.next(updatedItems);
  }

  clearCart(): void {
    this.cartItemsSubject.next([]);
    this.totalPriceSubject.next(0);
  }

  getCartItemCount(): number {
    return this.cartItemsSubject.getValue().reduce((count, item) => count + (item.amount || 0), 0);
  }
}
