<form [formGroup]="personalDetaildForm">
    <div class="form-control flex-column flex-full">
        <mat-label>{{'Contact Name' | translate}}</mat-label>
        <mat-form-field appearance="outline">
            <input matInput type="text" formControlName="contactName" spellcheck="false" />
            <mat-error *ngIf="personalDetaildForm.get('contactName')?.errors?.['required']">Field is
                required</mat-error>
        </mat-form-field>
    </div>

    <div class="form-control flex-column flex-half">
        <mat-label>{{'Contact Email' | translate}}</mat-label>
        <mat-form-field appearance="outline">
            <input matInput type="text" formControlName="contactEmail" spellcheck="false" />
            <mat-error *ngIf="personalDetaildForm.get('contactEmail')?.errors?.['required']">Field is
                required</mat-error>

            <mat-error *ngIf="personalDetaildForm.get('contactEmail')?.hasError('email')">
                Please enter a valid email address.
            </mat-error>
        </mat-form-field>
    </div>
</form>