export enum GixamDeviceStatusesEnum {        
    ENABLED = 1,
    DISABLED = 2
}

export namespace GixamDeviceStatusesEnum {
    export function toString(deviceStatus: GixamDeviceStatusesEnum): String {
        switch (deviceStatus) {            
            case GixamDeviceStatusesEnum.ENABLED:
                return 'Enabled';
            case GixamDeviceStatusesEnum.DISABLED:
                return 'Disabled';            
            default:
                return '';
        }
    }

    export function listAll() {
        return [                        
            GixamDeviceStatusesEnum.ENABLED,
            GixamDeviceStatusesEnum.DISABLED
        ]
    }

    export function parse(str: string) {
        return GixamDeviceStatusesEnum[str as keyof typeof GixamDeviceStatusesEnum];
    }
}

export default GixamDeviceStatusesEnum;

