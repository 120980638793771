import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { shareReplay, switchMap, of, map } from 'rxjs';
import { GixamStateModel, GixamCountryModel } from 'src/app/models/gixam-country.model';
import { GixamCountriesDataService } from 'src/app/services/gixam-countries-data.service';

@Component({
  selector: 'shipment-address-form',
  templateUrl: './gixam-shipment-address-form.component.html',
  styleUrls: ['./gixam-shipment-address-form.component.scss']
})
export class ShipmentAddressFormComponent {

  @Output() shipmentDetailsChange = new EventEmitter<FormGroup>();

  public shipmentAddressForm = new FormGroup({
    street: new FormControl<string | null>(null, [Validators.required]),
    city: new FormControl<string | null>(null, [Validators.required]),
    state: new FormControl<GixamStateModel | null>({ value: null, disabled: true }),
    country: new FormControl<GixamCountryModel | null>(null, [Validators.required]),
    postalCode: new FormControl<string | null>(null, [Validators.required])
  });

  countries_list = this.countriesService.getAllCountries().pipe(shareReplay(1));

  sa_states_list$ = this.shipmentAddressForm.get('country')!.valueChanges
    .pipe(
      switchMap(country => {
        if (country && country.code == 'US')
          return this.countriesService.getStatesByCountryCode(country.code);
        else
          return of(null);
      }),
      map(states => {
        if (states && states.length) {
          this.shipmentAddressForm.get('state')!.enable();
          return states;
        }
        else {
          this.shipmentAddressForm.get('state')!.disable();
          return null;
        }
      }), shareReplay(1)
    );

  constructor(private countriesService: GixamCountriesDataService) {}
 

  clearField(event: MouseEvent, element: any) {

    event.stopPropagation();

    if (element && element.ngControl) {
      element.ngControl.control.patchValue(null);
    }

  }

  countryCompare(c1: GixamCountryModel, c2: GixamCountryModel): boolean {
    return c1 && c2 ? c1.code === c2.code : c1 === c2;
  }

  stateCompare(c1: GixamStateModel, c2: GixamStateModel): boolean {
    return c1 && c2 ? c1.code === c2.code : c1 === c2;
  }

  isFormValid(): boolean {    
    return this.shipmentAddressForm.valid;
  }

  getFormValue(): any {
    return this.shipmentAddressForm.value;
  }

  getFirstInvalidField(): string | null {
    const controlsOrder = ['street', 'city', 'country', 'state', 'postalCode']; // Define the order
    for (const controlName of controlsOrder) {
      const control = this.shipmentAddressForm.get(controlName);
      if (control && control.invalid && control.touched) {
        return controlName;
      }
    }
    return null; // No invalid field
  }
  
  hasError(fieldName: string): boolean {
    const firstInvalid = this.getFirstInvalidField();
    return firstInvalid === fieldName;
  }
}
