<div class="dialog-wrapper patient-details-dialog">
    <div class="dialog-title">
        <button class="close-button" [mat-dialog-close]="false" type="button" tabindex="-1" mat-icon-button
            aria-label="Close">
            <mat-icon class="material-icons notranslate">clear</mat-icon>
        </button>
        <h3>{{productDetails.name}}</h3>
    </div>
    <div class="dialog-content">
        <img *ngIf="productDetails.imageUrl" [src]="productDetails.imageUrl" alt="Patient Image" class="patient-image">

        <p>{{ productDetails | json }}</p>

    </div>
    <!-- <div class="dialog-actions">
        <button type="button" [disabled]="!editPatientForm.valid" mat-raised-button color="primary"
            (click)="submit()">{{'SAVE_CHANGES' | translate}}</button>
        <button type="button" mat-raised-button color="default" [mat-dialog-close]="false">{{'CANCEL' |
            translate}}</button>
    </div> -->
</div>