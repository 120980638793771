import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, observable, of, switchMap, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GixamDevicesOrderRequestModel } from '../models/GixamDevicesOrderRequest.model';
import { GixamOrder, GixamOrderLine } from '../models/gixam-order.model';
import { GixamProduct } from '../models/gixam-product.model';
import { GixamProductResult, GixamProductsSearchParams } from 'src/app/models/GixamProductsSearchParams.model';
// Import the JSON data
import rawData from 'src/assets/data/products_list_demo.json'; // Adjust the path as necessary
import GixamProductCategoryEnum from '../models/enums/gixam-product-cateogry.enum';

@Injectable({
    providedIn: 'root'
})
export class GixamProductsDataService {
    private _endpointBasePath$ = of(`${environment.apiUrl}api/products`);

    constructor(private httpClient: HttpClient) { }
    getProducts(activeOnly: boolean = true): Observable<any[]> {
        // TODO ask Liran

        return this._endpointBasePath$.pipe(
            switchMap((endpointBasePath: string) => this.httpClient.get<GixamProduct[]>(`${endpointBasePath}/active`)),
            catchError(err => throwError(() => new Error(err))));

        }

    getProduct(productId: string): Observable<GixamProduct> {
        return this._endpointBasePath$.pipe(
            switchMap((endpointBasePath: string) => this.httpClient.get<GixamProduct>(`${endpointBasePath}`, { params: { productId: productId } })),
            catchError(err => throwError(() => new Error(err))));
    }


    public searchProducts(searchParams: GixamProductsSearchParams): Observable<any> {    
        return this._endpointBasePath$.pipe(
          switchMap((endpointBasePath: string) => this.httpClient.post<GixamProductResult>(`${endpointBasePath}/search`, searchParams)),
          catchError(err => throwError(()=> new Error(err))));
      }
}