<form [formGroup]="phoneForm">
    <ng-container formGroupName="phoneNumber">
        <div class="form-controls">
            <div class="form-control flex-column flex-half">
                <mat-label>Dailing Code</mat-label>
                <mat-form-field appearance="outline">
                    <mat-select formControlName="countryCode" #countryCodeSelect>
                        <mat-select-trigger>
                            <div class="mat-option-inner">
                                <span class="fi fi-{{countryCodeSelect.value?.code.toLowerCase()}}"></span>
                                <span>{{'(' + countryCodeSelect.value?.countryCode + ')'}}</span>
                            </div>
                        </mat-select-trigger>
                        <mat-option [value]="null">Select...</mat-option>
                        <mat-option *ngFor="let country of (countries_list |async)" [value]="country">
                            <div class="mat-option-inner">
                                <span class="fi fi-{{country.code.toLowerCase()}}"></span>
                                <span>{{country.name}}</span>
                            </div>
                        </mat-option>
                    </mat-select>

                </mat-form-field>
            </div>

            <div class="form-control flex-column flex-full">
                <mat-label>Phone Number</mat-label>
                <mat-form-field appearance="outline">
                    <input matInput type="text" formControlName="phoneNumber" />
                    <mat-error
                        *ngIf="phoneForm.get('phoneNumber')?.hasError('invalidPhoneNumber')">
                        {{phoneForm.get('phoneNumber')?.getError('invalidPhoneNumber')}}
                    </mat-error>
                </mat-form-field>
            </div>
        </div>

    </ng-container>
</form>