<div class="dialog-wrapper patient-details-dialog">
    <div class="dialog-title">
        <button class="close-button" [mat-dialog-close]="false" type="button" tabindex="-1" mat-icon-button
            aria-label="Close">
            <mat-icon class="material-icons notranslate">clear</mat-icon>
        </button>
        <h3>{{'Edit Patient Details' | translate}}</h3>
    </div>
    <div class="dialog-content">
        <form [formGroup]="editPatientForm">

            <div class="form-controls">
                <div class="form-control flex-column flex-full">
                    <mat-label>{{'FIRST_NAME' | translate}}</mat-label>
                    <mat-form-field appearance="outline">
                        <input matInput type="text" formControlName="firstName"  spellcheck="false"/>
                        <mat-error>This field is required</mat-error>
                    </mat-form-field>
                </div>

                <div class="form-control flex-column flex-full">
                    <mat-label>{{'LAST_NAME' | translate}}</mat-label>
                    <mat-form-field appearance="outline">
                        <input matInput type="text" formControlName="lastName"  spellcheck="false"/>
                        <mat-error>This field is required</mat-error>
                    </mat-form-field>
                </div>
            </div>

            <!-- <div class="form-control flex-column">
                <mat-label>{{'GENDER' | translate}}</mat-label>
                <mat-form-field appearance="outline">
                    <mat-select formControlName="gender">
                        <mat-option *ngFor="let gxmGender of gixamGenders"
                            [value]="gxmGender">{{gxmGender}}</mat-option>
                    </mat-select>                   
                </mat-form-field>
            </div> -->

            <div class="form-control flex-column">
                <mat-label>{{'EMAIL' | translate}}</mat-label>
                <mat-form-field appearance="outline">
                    <input matInput type="text" formControlName="email"  spellcheck="false"/>
                    <mat-error>This field is required</mat-error>
                </mat-form-field>
            </div>

            <div class="form-controls" formGroupName="phone">
                <div class="form-control flex-column flex-full">
                    <mat-label>{{'COUNTRY_CODE' | translate}}</mat-label>
                    <mat-form-field appearance="outline">                    
                        <mat-select formControlName="countryCode" #countryCodeSelect>
                            <mat-select-trigger>
                                <div class="mat-option-inner">
                                    <span class="fi fi-{{countryCodeSelect.value?.code.toLowerCase()}}"></span>
                                    <span>{{'(' + countryCodeSelect.value?.countryCode + ')'}}</span>                                
                                </div>
                              </mat-select-trigger>
                              <!-- <mat-option [value]="null">Select...</mat-option> -->
                            <mat-option *ngFor="let country of (countries_list$ |async)" [value]="country">
                                <div class="mat-option-inner">
                                    <span class="fi fi-{{country.code.toLowerCase()}}"></span>
                                    <span>{{country.name}}</span>
                                </div>
                            </mat-option>
                            
                        </mat-select>
                        <button matSuffix mat-icon-button aria-label="Clear" color="default" *ngIf="editPatientForm.get('phone.countryCode')?.enabled && editPatientForm.get('phone')?.get('countryCode')?.value" type="button" (click)="clearField($event,countryCodeSelect)">
                            <mat-icon class="material-icons material-icons-outlined">close</mat-icon>
                        </button>
                    </mat-form-field>
                </div>
    
                <div class="form-control flex-column flex-full">
                    <mat-label>{{'PHONE_NUMBER' | translate}}</mat-label>
                    <mat-form-field appearance="outline">
                        <input matInput type="text" formControlName="phoneNumber" />                    
                        <mat-error *ngIf="editPatientForm.get('phone')?.get('phoneNumber')?.hasError('invalidPhoneNumber')">
                            {{editPatientForm.get('phone')?.get('phoneNumber')?.getError('invalidPhoneNumber')}}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <!-- <div class="form-controls">
                <div class="form-control flex-column flex-half">
                    <mat-label>{{'COUNTRY_CODE' | translate}}</mat-label>
                    <mat-form-field appearance="outline">
                        <mat-select formControlName="countryCode">
                            <mat-option *ngFor="let country of (countries_list$ | async)"
                            [value]="country">{{country.name}}</mat-option>                            
                        </mat-select>                        
                    </mat-form-field>
                </div>

                <div class="form-control flex-column flex-full">
                    <mat-label>{{'PHONE_NUMBER' | translate}}</mat-label>
                    <mat-form-field appearance="outline">
                        <input matInput type="text" formControlName="phoneNumber" />
                        <mat-error>This field is required</mat-error>
                    </mat-form-field>
                </div>
            </div> -->
            <!-- <div class="form-controls">
                <div class="form-control flex-column flex-full">
                    <mat-label>{{'STREET' | translate}}</mat-label>
                    <mat-form-field appearance="outline">
                        <input matInput type="text" formControlName="street" spellcheck="false" />
                    </mat-form-field>
                </div>
                <div class="form-control flex-column flex-half">
                    <mat-label>{{'CITY' | translate}}</mat-label>
                    <mat-form-field appearance="outline">
                        <input matInput type="text" formControlName="city" spellcheck="false" />
                    </mat-form-field>
                </div>


            </div>

            <div class="form-controls">
                <div class="form-control flex-column flex-full">
                    <mat-label>{{'COUNTRY' | translate}}</mat-label>
                    <mat-form-field appearance="outline">
                        <mat-select formControlName="country" [compareWith]="countryCompare" #countrySelect>
                            <mat-option *ngFor="let country of (countries_list$ | async)"
                            [value]="country.code">{{country.name}}</mat-option>
                        </mat-select>
                        <button matSuffix mat-icon-button aria-label="Clear" *ngIf="editPatientForm.get('country')?.enabled && editPatientForm.get('country')?.value?.length" color="default" type="button" (click)="clearField($event,countrySelect)">
                            <mat-icon class="material-icons material-icons-outlined">close</mat-icon>
                        </button>
                    </mat-form-field>
                </div>

                <div class="form-control flex-column flex-full">
                    <mat-label>{{'STATE' | translate}}</mat-label>
                    <mat-form-field appearance="outline">
                        <mat-select formControlName="state" [compareWith]="stateCompare" #stateSelect>
                            <mat-option *ngFor="let state of (states_list$ | async)"
                                [value]="state.code">{{state.name}}</mat-option>
                        </mat-select>
                        <button matSuffix *ngIf="editPatientForm.get('state')?.enabled && editPatientForm.get('state')?.value?.length" mat-icon-button aria-label="Clear" color="default" type="button" (click)="clearField($event,stateSelect)">
                            <mat-icon class="material-icons material-icons-outlined">close</mat-icon>
                        </button>
                    </mat-form-field>
                </div>

                <div class="form-control flex-column flex-half">
                    <mat-label>{{'POSTAL_CODE' | translate}}</mat-label>
                    <mat-form-field appearance="outline">
                        <input matInput type="text" formControlName="postalCode" spellcheck="false" />
                    </mat-form-field>
                </div>
            </div> -->
        </form>
    </div>
    <div class="dialog-actions">
        <button type="button" [disabled]="!editPatientForm.valid" mat-raised-button color="primary"
            (click)="submit()">{{'SAVE_CHANGES' | translate}}</button>
        <button type="button" mat-raised-button color="default" [mat-dialog-close]="false">{{'CANCEL' | translate}}</button>
    </div>
</div>