import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { GixamAddClinicModel } from 'src/app/models/gixam-add-clinic.model';
import { GixamCountryModel } from 'src/app/models/gixam-country.model';
import { GixamCountriesDataService } from 'src/app/services/gixam-countries-data.service';
import { phoneNumberValidator } from 'src/app/validators/phone-number-validator';

@Component({
  selector: 'gixam-create-clinic-form',
  templateUrl: './create-clinic-form.component.html',
  styleUrls: ['./create-clinic-form.component.scss']
})
export class CreateClinicFormComponent {

  @Output() savePressed = new EventEmitter<any>();

  countries$: Observable<GixamCountryModel[]> = this.countriesDataService.getAllCountries();

  clinicForm: FormGroup = new FormGroup({
    name: new FormControl<string | null>(null, [Validators.required, Validators.maxLength(30)]),
    email: new FormControl<string | null>(null, [Validators.required, Validators.email]),
    phone: new FormGroup({
      countryCode: new FormControl<string | null>(null),
      phoneNumber: new FormControl<string | null>(null),
    },{updateOn:'blur', validators:phoneNumberValidator}),
    contact: new FormGroup({
      firstName: new FormControl<string | null>(null, [Validators.maxLength(20)]),
      lastName: new FormControl<string | null>(null, [Validators.maxLength(30)]),      
    })
  });


  constructor(private countriesDataService: GixamCountriesDataService) {

  }
  

  ngOnInit(){

    this.clinicForm.get('phone.countryCode')?.valueChanges
    .subscribe(data=>{
      if (data == null){
        var phoneNumberControl = this.clinicForm.get('phone.phoneNumber');
        if (!phoneNumberControl?.value){
          phoneNumberControl?.setErrors(null);
        }
      }
    });

  }
  
  private generateModel() {

    var model = this.clinicForm.getRawValue();

    var addClinicModel = new GixamAddClinicModel();
    addClinicModel.name = model.name;
    addClinicModel.email = model.email;
    addClinicModel.countryCode = model.phone.countryCode.countryCode;
    addClinicModel.phoneNumber = model.phone.phoneNumber;
    addClinicModel.adminFirstName = model.contact.firstName;
    addClinicModel.adminLastName = model.contact.lastName;
    return addClinicModel;

  }

  submit() {

    if (this.clinicForm.valid) {

      var clinicModel = this.generateModel();
      this.savePressed.emit(clinicModel);
    }
  }
}
