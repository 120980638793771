import { Component } from '@angular/core';
import { GixamShoppingCartService } from './gixam-shopping-cart.service';

@Component({
  selector: 'app-gixam-shopping-cart-dialog',
  templateUrl: './gixam-shopping-cart-dialog.component.html',
  styleUrls: ['./gixam-shopping-cart-dialog.component.scss'],
  providers: [GixamShoppingCartService]
})
export class GixamShoppingCartDialogComponent {

}
