<ng-container>
    <div *ngIf="(products$ | async)?.length ?? 0 > 0; else loadingTemplate" style="position: relative;">

        <div class="products-list-container">
            <ng-container>
                <div *ngFor="let product of products$ | async" class="product-items" (click)="goToProduct(product)">
                    <span style="width: 100%; color: #0184BA;">{{product.name}}</span>
                    <hr class="line" />
                    <div class="inside-details-container">
                        <img src={{product.image}} />
                        <div>
                            <div>{{product.description}}</div>
                            <div *ngIf="product.generation" class="details">generation: {{product.generation}}</div>
                            <div *ngIf="product.model" class="details">model: {{product.model}}</div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>

    </div>


    <ng-template #loadingTemplate>
        <p class="not-found">
            {{'EMPTY_TABLE_RESULTS_NO_{PRODUCTS}_FOUND.' | translate}}</p>
    </ng-template>


    <ngx-ui-loader [loaderId]="'products-loader'" [hasProgressBar]="false"></ngx-ui-loader>
    
</ng-container>