export enum GixamDeviceStatesEnum {    
    NOT_SUPPLIED = 1,
    SUPPLIED = 2    
}

export namespace GixamDeviceStatesEnum {
    export function toString(deviceState: GixamDeviceStatesEnum): String {
        switch (deviceState) {
            case GixamDeviceStatesEnum.NOT_SUPPLIED:
                return 'Not Supplied';
            case GixamDeviceStatesEnum.SUPPLIED:
                return 'Supplied';            
            default:
                return '';
        }
    }

    export function listAll() {
        return [            
            GixamDeviceStatesEnum.NOT_SUPPLIED,
            GixamDeviceStatesEnum.SUPPLIED
        ]
    }

    export function parse(str: string) {
        return GixamDeviceStatesEnum[str as keyof typeof GixamDeviceStatesEnum];
    }
}

export default GixamDeviceStatesEnum;

