import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'gixam-devices-table-filter',
  templateUrl: './gixam-devices-table-filter.component.html',
  styleUrls: ['./gixam-devices-table-filter.component.scss']
})
export class GixamDevicesTableFilterComponent {

  devicesFilterForm:FormGroup;

  constructor(){

  }

  ngOnInit(){
    this.initForm();
  }
  
  initForm(){
    this.devicesFilterForm = new FormGroup({
      freeText: new FormControl<string | null>(null),
      dateRange: new FormGroup({
        fromDate: new FormControl<Date | null>(null),
        toDate: new FormControl<Date | null>(null)
      })
    });
  }
}
