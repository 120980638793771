<div class="component-wrapper">
    <form [formGroup]="devicesFilterForm" class="horizontal-form">
        <div class="form-control">
            <mat-form-field appearance="outline">
                <mat-label>Search</mat-label>
                <input matInput formControlName="freeText" placeholder="MM/DD/YYYY"/>
            </mat-form-field>
        </div>

        <!-- <div class="form-control">
            <mat-form-field appearance="outline">
                <mat-label>Device</mat-label>
                <mat-select formControlName="deviceId">
                    <mat-option value="">Select</mat-option>
                </mat-select>
            </mat-form-field>
        </div> -->

        <div class="form-control">
            <mat-form-field appearance="outline">
                <mat-label>Date Range</mat-label>
                <mat-date-range-input formGroupName="dateRange" [rangePicker]="devicesDatePicker">
                    <input matStartDate readonly placeholder="MM/DD/YYYY" formControlName="fromDate">
                    <input matEndDate readonly placeholder="MM/DD/YYYY" formControlName="toDate">
                </mat-date-range-input>
                <!-- <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint> -->
                <mat-datepicker-toggle matIconSuffix [for]="devicesDatePicker"></mat-datepicker-toggle>
                <mat-date-range-picker #devicesDatePicker></mat-date-range-picker>
            </mat-form-field>
        </div>
     
    </form>
</div>