import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, of, switchMap, tap, throwError } from 'rxjs';
import { JubaanLanguageModel } from '../models/JubaanLanguage.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class JubaanLanguagesService {

  private _endpointBasePath$ = of(environment.apiUrl + 'api/languages');

  public languagesList$ = this.getAll();

  constructor(private httpClient: HttpClient) { }

  getAll() {
    return this._endpointBasePath$.pipe(
      switchMap((endpointBasePath: string) => this.httpClient.get<any>(endpointBasePath + 'all').pipe(
        map(data => data.languages)
      )),
      catchError(err => throwError(() => new Error(err))));
  }

  getLanguageById(languageId: string) {
    return this._endpointBasePath$.pipe(
      switchMap((endpointBasePath: string) => this.httpClient.get<any>(endpointBasePath + 'get',
        {
          params: {
            languageId: languageId
          }
        }).pipe(
          map(data => data.language)
        )),
      catchError(err => throwError(() => new Error(err))));
  }

  getLanguageByCode(languageCode: number) {
    return this._endpointBasePath$.pipe(
      switchMap((endpointBasePath: string) => this.httpClient.get<any>(endpointBasePath + '/getByCode',
        {
          params: {
            languageCode: languageCode
          }
        }).pipe(
          map(data => data.language)
        )),
      catchError(err => throwError(() => new Error(err))));
  }

  getLanguageTranslations(languageCode: string) {
    return this._endpointBasePath$.pipe(
      switchMap((endpointBasePath: string) => this.httpClient.get<any>(`${endpointBasePath}/${languageCode}/translations`).pipe(
        map(data => {
          return data.translationValues;
        }))),
      catchError(err => throwError(() => new Error(err))));
  }

  xxxxxxx() {

    var obj = {
      name: "davidr",
      password: "Orm203040!",
      organisationid: -1
    }

    return this._endpointBasePath$.pipe(
      switchMap((endpointBasePath: string) => this.httpClient.post<any>("https://bo.drjubot.com/users.asmx/userlogin",obj)),
      catchError(err => throwError(() => new Error(err))));
//    return this._endpointBasePath$.pipe(

    // return this._endpointBasePath$.pipe(

      // switchMap((endpointBasePath: string) => fetch("https://bo.drjubot.com/users.asmx/userlogin", {
      //   method: 'POST',
      //   headers: {
      //     'content-type': 'application/json;charset=UTF-8',
      //     'accept':'application/json'
      //   },
      //   body: JSON.stringify(obj)
      // })),
      // catchError(err => throwError(() => new Error(err))));
  }


}
