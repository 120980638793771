import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { shareReplay } from 'rxjs';
import { GixamCountryModel } from 'src/app/models/gixam-country.model';
import { GixamPhoneDetails } from 'src/app/models/gixam-order.model';
import { GixamCountriesDataService } from 'src/app/services/gixam-countries-data.service';
import { phoneNumberValidator } from 'src/app/validators/phone-number-validator';

@Component({
  selector: 'gixam-phone-form',
  templateUrl: './gixam-phone-form.component.html',
  styleUrls: ['./gixam-phone-form.component.scss'],
  providers: [GixamPhoneDetails]
})
export class GixamPhoneFormComponent {

  @Output() shipmentDetailsChange = new EventEmitter<FormGroup>();

  phoneForm = new FormGroup({
    phoneNumber: new FormGroup({
      countryCode: new FormControl<GixamCountryModel | null>(null, [Validators.required]),
      phoneNumber: new FormControl<string | null>(null, [Validators.required])
    }, { updateOn: 'blur', validators: phoneNumberValidator.bind(this) }),
  });

  countries_list = this.countriesService.getAllCountries().pipe(shareReplay(1));

  constructor(
    private countriesService: GixamCountriesDataService) {
  }

  clearField(event: MouseEvent, element: any) {

    event.stopPropagation();

    if (element && element.ngControl) {
      element.ngControl.control.patchValue(null);
    }
  }

  isFormValid(): boolean {
    
    return this.phoneForm.valid;
  }

  getFormValue(): any {
    return this.phoneForm.value;

  }

}
