import GixamProductCategoryEnum from "./enums/gixam-product-cateogry.enum";
import { GixamProduct } from "./gixam-product.model";
import { GixmaSearchParamsBaseModel } from "./GixamSearchParamsBase.model";
import { GixamResponseBaseModel } from "./Response/GixamResponseBase.model";


export class GixamProductsSearchParams extends GixmaSearchParamsBaseModel{
    productCategory:GixamProductCategoryEnum | null = null;

    constructor(){
        super();
    }
}

export class GixamPagedProductsList {
    count: number;
    page: number;
    size: number;
    pagedProducts: GixamProduct[]
}

export class GixamProductResult extends GixamResponseBaseModel{
    result: GixamPagedProductsList;
}