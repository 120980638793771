<div class="mainShoppingCart" (click)="$event.stopPropagation()">
    <h3>Shopping Cart</h3>
    <hr class="line" style="  border-bottom: 1.5px solid #0184BA;">
    <div class="menu-inner innerShoppingCart">
        <ng-container *ngIf="cartItems.length > 0; else emptyCart">
            <div style="overflow-y: auto">
                <div class="item" *ngFor="let item of cartItems; let i = index; let isLast = last">

                    <div *ngIf="item.productType == gixamProductCategoriesEnum.Digital_Product">
                        <gixam-shopping-cart-digital-item [item]="item"></gixam-shopping-cart-digital-item>
                    </div>

                    <div *ngIf="item.productType == gixamProductCategoriesEnum.Physical_Product">
                        <gixam-shopping-cart-physical-item [item]="item"></gixam-shopping-cart-physical-item>
                    </div>

                    <hr class="line" *ngIf="!isLast">
                </div>

            </div>

            <hr class="line" style="  border-bottom: 1.5px solid #0184BA;">
            <div class="totalPrice">
                Total Price: {{totalPrice}}$
            </div>
            
            <button mat-raised-button type="text" color="primary" style="width: 40%; ">
                <span>{{'CHECKOUT' | translate}}</span>
            </button>
        </ng-container>
    </div>


    <ng-template #emptyCart>
        <p>Your cart is empty.</p>
    </ng-template>

</div>