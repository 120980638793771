import { Component } from '@angular/core';
import { AccountService } from './account/account.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { JubaanLanguagesService } from './services/jubaan-languages.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Gixam Cloud Upload Management';

  // public userAuthenticated = false;
  // private subscription: Subscription;

  constructor(private translateService: TranslateService,
    private languageService: JubaanLanguagesService,
    private accountService: AccountService,
    private domSanitizer: DomSanitizer,
    private iconRegistry: MatIconRegistry) {

    this.translateService.setDefaultLang('us');
    this.translateService.use('us');

    this.iconRegistry.addSvgIcon('order_devices', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/order_devices.svg"));
    this.iconRegistry.addSvgIcon('device_recharge', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/device_recharge.svg"));
    this.iconRegistry.addSvgIcon('paypal_icon', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/paypal_icon.svg"));
    this.iconRegistry.addSvgIcon('venmo_icon', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/venmo_icon.svg"));
    this.iconRegistry.addSvgIcon('payment_method', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/payment_method.svg"));

    this.iconRegistry.addSvgIcon('pm_mastercard_icon', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/pm_mastercard_icon.svg"));
    this.iconRegistry.addSvgIcon('pm_maestro_icon', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/pm_maestro_icon.svg"));
    this.iconRegistry.addSvgIcon('pm_visa_icon', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/pm_visa_icon.svg"));
    this.iconRegistry.addSvgIcon('pm_discover_icon', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/pm_discover_icon.svg"));
    this.iconRegistry.addSvgIcon('lock_icon', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/lock_icon.svg"));
    this.iconRegistry.addSvgIcon('whatsapp', this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/images/whatsapp.svg"));
    this.iconRegistry.addSvgIcon('send-mail', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/send-mail.svg"));

    this.iconRegistry.addSvgIcon('device_commands', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/device_commands_1.svg"));


    // this.accountService.isUserAuthenticated()
    //   .then(isAuthenticated => {
    //     if (isAuthenticated) {
    //       if (!this.devicesHubService?.isConnected()) {

    //         this.subscription =this.devicesHubService.initSignalR(this.accountService.userId || '')
    //           .pipe(
    //             switchMap(_ => this.devicesHubService.startConnection()))
    //           .subscribe(() => {
    //             this.devicesHubService.attachDeviceUpdateEvent();
    //             this.devicesHubService.attchCloudSyncEventListeners();
    //           });
    //       }
    //     }
    //   })
  }

  ngOnInit() {

  }

  ngOnDestroy() {

    // this.subscription.unsubscribe();
  }
}
