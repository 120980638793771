import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { PaymentIntent } from '@stripe/stripe-js';
import { catchError, of, switchMap, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class GixamStripeService {

  private _endpointBasePath$ = of(environment.apiUrl + 'api/orders');


  httpClient = inject(HttpClient);

  constructor() { }


  createCheckoutSession(model:any){
    
    return this._endpointBasePath$.pipe(
      switchMap(endpointBasePath=> this.httpClient.post<any>(`${endpointBasePath}/stripe/checkout-session/create`, model)),
      catchError(err => throwError(()=> new Error(err))));    
  }



}
