
export class GixamAddClinicModel{
    name:string;
    email:string;
    phoneNumber: string;
    countryCode: string;
    adminFirstName:string;
    adminLastName:string;
}

export class GixmClinicModel{
    name:string;
    email:string;
    phone:string;    
    adminName:string;    
}