export enum GixamGendersEnum{
    MALE = 1,
    FEMALE = 2,
    OTHER = 3
}

export namespace GixamGendersEnum{
    export function toString(gxmGender:GixamGendersEnum){
        switch(gxmGender){
            case GixamGendersEnum.MALE:
                return "Male";
            case GixamGendersEnum.FEMALE:
                return "Female";
            case GixamGendersEnum.OTHER:
                return "Other";
            default : return '';
        }
    }

    export function listAll(){
        return [
            GixamGendersEnum.MALE,
            GixamGendersEnum.FEMALE,
            GixamGendersEnum.OTHER,
        ]
    }

    export function parse(str:string){
        return GixamGendersEnum[str as keyof typeof GixamGendersEnum]
    }
}

export default GixamGendersEnum;