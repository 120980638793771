import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/modules/shared/shared.module';
import { GixamShoppingCartDialogComponent } from './gixam-shopping-cart-dialog.component';



@NgModule({
  declarations: [GixamShoppingCartDialogComponent],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports:[GixamShoppingCartDialogComponent
  ]
})
export class GixamShoppingCartDialogModule { }
