import { Injectable } from '@angular/core';
import { LoginResponseModel } from '../models/Response/gixam-login-response.model';

@Injectable({
  providedIn: 'root'
})
export class JubaanStorageService {

  constructor() { }


  public setUserName(userName:string){
    this.setSessionItem("userName",userName);
  }

  public getUserName(){
    return this.getSessionItem("userName");
  }

  public setAuthorizationData(authData: string) {
    return this.setSessionItem("userData", authData);
  }

  public setCredentialsData(credData: string) {
    return this.setSessionItem("token", credData);
  }
  
  public getAuthorizationData() {
    return this.getTypedSessionItem<LoginResponseModel>("userData");
  }
 
  public getCredentialsData(): string | null{
    return this.getSessionItem("token");
  }

  public getTypedSessionItem<T>(key:string): T | null {
    
    var data = this.getSessionItem(key) || null;
    
    if (data)
      return (JSON.parse(data)) as T;
    else
      return null;
  }

  private getSessionItem(key: string): string | null {
    return sessionStorage.getItem(key);
  }

  private setSessionItem(key: string, value: string) {
    sessionStorage.setItem(key, value);
  }

  deleteSessionItem(key: string) {
    sessionStorage.removeItem(key);
  }

  clearSession() {
    sessionStorage.clear();
  }

  getLocalItem(key: string): string | null {
    return localStorage.getItem(key);
  }

  setLocalItem(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  deletelocalItem(key: string) {
    localStorage.removeItem(key);
  }

  clearLocal() {
    localStorage.clear();
  }
}
