import { BehaviorSubject, Observable, Subject, catchError, distinctUntilChanged, of, shareReplay, takeUntil, tap } from "rxjs";
import { GixamProductsDataService } from "src/app/services/gixam-products-data.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { GixamProductsListService } from "./gixam-products-list.service";


export class GixamProductsListDataSource {
    private destroy$ = new Subject<void>();

    private productsSubject = new BehaviorSubject<any[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);

    private loaderName = "products-loader";

    private _loading$ = new BehaviorSubject<boolean>(false);
    public isLoading$ = this._loading$.pipe(takeUntil(this.destroy$), distinctUntilChanged(), shareReplay(1));

    public products$ = this.getProducts();

    constructor(private productsDataService: GixamProductsDataService,
        private productsListService: GixamProductsListService,
        private loaderService: NgxUiLoaderService) { }



    private getProducts() {
        this.loaderService.startLoader(this.loaderName)
        return this.productsDataService.getProducts()
            .pipe(tap((data) => {
                this.loaderService.stopLoader(this.loaderName)}),
                catchError(error => {
                    return of([]);
                }));
    }



}
