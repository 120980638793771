

import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GixamProductsDataService } from 'src/app/services/gixam-products-data.service';
import { GixamProductsTableService } from './gixam-products-table.service';
import { debounceTime, map, shareReplay } from 'rxjs';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { FormControl } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Sort } from '@angular/material/sort';
import { GixamProductsTableDataSource } from './gixam-products-table-data-source';
import { MatDialog } from '@angular/material/dialog';
import { GixamSnackbarService } from 'src/app/services/gixam-snackbar.service';
import { GixamProductDetailsDialogComponent } from 'src/app/gixam/products/product-physical-details-dialog/gixam-product-physical-details-dialog.component';


@Component({
  selector: 'gixam-products-table',
  templateUrl: './gixam-products-table.component.html',
  styleUrls: ['./gixam-products-table.component.scss']
})
export class GixamProductsTableComponent {

  @ViewChild(MatPaginator) paginator: MatPaginator;

  amountPerPageOptions = [10, 20, 50, 100];
  perPageControl = new FormControl<number | null>(this.amountPerPageOptions[0]);
  perPageChanged$ = this.perPageControl.valueChanges.pipe(debounceTime(200), map(value => +value!));


  productsTableDataSource = new GixamProductsTableDataSource(this.productsDataService, this.productsTableService, this.loaderService);


  searchOptions$ = this.productsTableService.searchOptions$;

  pageRowsCount$ = this.productsTableDataSource.pageRows$.pipe(map(rows => rows ? rows.length : 0), shareReplay(1));
  totalRowsCount$ = this.productsTableDataSource.totalRowsCount$;

  // isLoading$ = this.productsTableDataSource.isLoading$;

  displayedColumns: string[] = ['name', 'description', 'generation', 'model', 'actions'];

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private loaderService: NgxUiLoaderService,
    private productsDataService: GixamProductsDataService,
    private productsTableService: GixamProductsTableService,
    private dialog: MatDialog,
    private snackbarService: GixamSnackbarService

  ) { }

  ngOnInit() {

    this.productsTableService.updatePageSize(this.amountPerPageOptions[0]);

    this.perPageChanged$.pipe().subscribe(
      pageSize => {
        this.productsTableService.updatePageSize(pageSize);
      });

  }


  goToProduct(product: any) {
    this.router.navigate(['../product/' + product.id], { relativeTo: this.activatedRoute, state: { product: product } });
  }

  trackByFunction(index: number, item: any) {
    return item.id;
  }

  pageChange(event: PageEvent) {
    this.productsTableService.updatePageNumber(event.pageIndex);
  }

  productSortChanged(sortState: Sort) {
    this.productsTableService.updateSortColumn(sortState.active, sortState.direction);
  }

  productDetailsDialog(event: MouseEvent, product: any) {
    event.stopPropagation();
    this.dialog.open(GixamProductDetailsDialogComponent, {
      data: {
        product: product
      }
    })
      .afterClosed()
      .subscribe(updatedShoppingCart => {
        if (updatedShoppingCart) {
          this.snackbarService.showGeneralMessage("Patient Updated successfully.");
          // product.patient = updatedShoppingCart;
        }
      });
  }
}


