<div class="app-header">
    <mat-toolbar color="primary">
        <mat-toolbar-row>
            <div class="logo-wrapper">
                <img id="jubaan-logo" src="assets/images/jubaan_logo.svg" />
            </div>
            <div class="nav-links">
                <a [routerLink]="['/site/home']" routerLinkActive="router-link-active">Home</a>               
                <a [routerLink]="['/site/technology']" routerLinkActive="router-link-active">Technology</a>
                <a [routerLink]="['/site/microbiosem']" routerLinkActive="router-link-active">Microbiomes</a>
                <a [routerLink]="['/site/about']" routerLinkActive="router-link-active">About</a>
                <!-- <a [routerLink]="['/site/contact-us']" routerLinkActive="router-link-active">Contact Us</a> -->
            </div>
            <!-- <div class="nav-links" *ngIf="isAuthenticated">
                <a [routerLink]="['/gixam/devices']" routerLinkActive="router-link-active">Devices</a>
                <a [routerLink]="['/gixam/exams']" routerLinkActive="router-link-active">Studies</a>
                <a [routerLink]="['/gixam/billing']" routerLinkActive="router-link-active">Billing</a>
            </div> -->
            <div class="help-wrapper">
                <button mat-icon-button type="button">
                    <mat-icon class="material-icons-symbols">help</mat-icon>
                </button>
            </div>
            <!-- <div class="notifications-wrapper" *ngIf="isAuthenticated">
                <button mat-icon-button type="button">
                    <mat-icon>circle_notifications</mat-icon>
                </button>
            </div> -->
            <div class="login-wrapper">
                <button mat-button type="button" (click)="signup()">
                    <span>{{'REGISTER' | translate}}</span>
                    <!-- <mat-icon>chevron_right</mat-icon> -->
                </button>
            </div>
            <div class="login-wrapper">
                <button mat-button type="button" (click)="login()">
                    <span>{{'LOGIN' | translate}}</span>
                    <!-- <mat-icon>chevron_right</mat-icon> -->
                </button>
            </div>
            <!-- <div class="user-wrapper" *ngIf="isAuthenticated">
                <button type="button" mat-flat-button [matMenuTriggerFor]="menu" color="primary">
                    <mat-icon>account_circle</mat-icon>
                    <span>{{'Liran Peleg'}}</span>
                </button>
                <mat-menu #menu="matMenu">
                    <button  type="button" mat-menu-item>Preferences</button>
                    <button  type="button" mat-menu-item>Invite</button>
                    <mat-divider></mat-divider>
                    <button type="button" mat-menu-item (click)="logout()">Logout</button>
                </mat-menu>
            </div> -->
        </mat-toolbar-row>
    </mat-toolbar>
</div>