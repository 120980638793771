import { Component, Input } from '@angular/core';
import { GixamShoppingCartService } from 'src/app/layout/headers/gixam-header/gixam-shopping-cart-dialog/gixam-shopping-cart.service';

@Component({
  selector: 'gixam-shopping-cart-physical-item',
  templateUrl: './gixam-shopping-cart-physical-item.component.html',
  styleUrls: ['./gixam-shopping-cart-physical-item.component.scss']
})
export class GixamShoppingCartPhysicalItemComponent {
  @Input() item: any
  constructor(private cartService: GixamShoppingCartService) { }


  removeItem() {
    this.cartService.removeItem(this.item);
  }
}
