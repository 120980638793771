<div class="dialog-wrapper">
    <div class="dialog-title">
        <button class="close-button" [mat-dialog-close]="false" type="button" tabindex="-1" mat-icon-button
            aria-label="Close">
            <mat-icon class="material-icons notranslate">clear</mat-icon>
        </button>
        <h3>{{'SHOPPING_CART' | translate}}</h3>
    </div>
    <div class="dialog-content">
        <!-- <app-gixam-shopping-cart></app-gixam-shopping-cart> -->
    </div>
    <div class="dialog-actions">

        <button mat-raised-button type="text" color="primary" [disabled]="true">
            <span>{{'CHECKOUT' | translate}}</span>
        </button>
        <button mat-raised-button id="btn-close" color="default">
            <span>{{'CANCEL' | translate}}</span>
        </button>
    </div>
</div>