import { Component } from '@angular/core';

@Component({
  selector: 'app-gixam-orders-table',
  templateUrl: './gixam-orders-table.component.html',
  styleUrls: ['./gixam-orders-table.component.scss']
})
export class GixamOrdersTableComponent {

}
