

export class GixamDevicesSearchParams{
    freeText:string;
    fromData:Date;
    toDate:Date;
    pageSize:number = 10;
    pageNumber:number = 1;
    sortColumn:string;
    sortDirection:string = 'asc';
}