export class GixamCountryModel{
id:string;
code:string;
name:string;
countryCode: string;   
}

export class GixamStateModel{
    id:string;
    code:string;
    name:string;
}