import { TranslateLoader } from "@ngx-translate/core";
import { JubaanLanguagesService } from "../services/jubaan-languages.service";
import { Observable, of } from "rxjs";

export class GixamCustomTranslationLoader implements TranslateLoader{

    /**
     *
     */
    constructor(private languageService: JubaanLanguagesService) {        
        
    }
    getTranslation(lang: string): Observable<any> {
         return this.languageService.getLanguageTranslations(lang);        
    }
}