import GixamGender from "./enums/gixam-genders.enum";

export class GixamPatientModel{
    id:number;
    firstName:string;
    lastName:string;
    email:string;
    patientId:string;
    countryCode:string;
    phoneNumber:string;
    gender:GixamGender;
    street:string;
    city:string;
    state:string;
    country:string;
    postalCode:string;
}

export class GixamPatientUpdateModel{
    id:string;
    firstName:string | null;
    lastName:string | null;
    email:string | null;
    countryCode:string | null;
    phoneNumber:string | null;
    street:string | null;
    city:string | null;
    state:string | null;
    country:string | null;
    postalCode:string | null;
}