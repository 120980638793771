
import { Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { GixamDevicesDataService } from 'src/app/services/gixam-devices-data.service';
import { Subject, debounceTime, filter, map, of, pairwise, switchMap, switchMapTo, tap} from 'rxjs';
import { GixamStudiesTableService } from '../gixam-studies-table/gixam-studies-table.service';
import { GixamStudiesSearchParams } from 'src/app/models/GixamStudiesSearchParams.model';
import moment from 'moment';
import { MatSelect } from '@angular/material/select';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'gixam-studies-table-filter',
  templateUrl: './gixam-studies-table-filter.component.html',
  styleUrls: ['./gixam-studies-table-filter.component.scss']
})
export class GixamStudiesTableFilterComponent implements OnDestroy {
 
  @ViewChild('startDate') startDateRef: ElementRef;
  @ViewChild('endDate') endDateRef: ElementRef;
  
  max = new Date();
  
  studiesFilterForm: FormGroup = new FormGroup({
    freeText: new FormControl<string | null>(null),
    devices: new FormControl<string[] | null>(null),
    dateRange: new FormGroup({
      fromDate: new FormControl<Date | null>(null),
      toDate: new FormControl<Date | null>(null)
    })
  });

  destroy$ = new Subject<void>();

  userDevices$ = this.devicesDataService.getOperatorDevices().pipe(map(d => d.results));

  constructor(
    private devicesDataService: GixamDevicesDataService,
    private studiesTableService: GixamStudiesTableService,
    private activatedRoute:ActivatedRoute) {

  }

  ngOnInit() {   
    
    this.studiesFilterForm.valueChanges
    .pipe(debounceTime(500),    
    filter((formValues)=> (formValues.dateRange.fromDate && formValues.dateRange.toDate) || (!formValues.dateRange.fromDate && !formValues.dateRange.toDate)),
    switchMap((_)=>of(this.generateFilterModel())))
    .subscribe(filterModel=>{
      this.studiesTableService.updateFilter(filterModel);
    });

    // this.studiesFilterForm.get('devices')?.valueChanges
    // .pipe(debounceTime(500),    
    // switchMap((_)=>of(this.generateFilterModel())))
    // .subscribe(filterModel=>{
    //   this.studiesTableService.updateFilter(filterModel);
    // });

    // this.studiesFilterForm.get('dateRange')?.valueChanges
    // .pipe(debounceTime(500),    
    // filter(formValues=>(formValues.fromDate && formValues.toDate) || (!formValues.fromDate && !formValues.toDate)),
    // switchMap((_)=>of(this.generateFilterModel())))
    // .subscribe(filterModel=>{
    //   this.studiesTableService.updateFilter(filterModel);
    // });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }
 
  deselectAllDevices() {
    this.studiesFilterForm.get('devices')?.reset(null);
  }

  selectAllDevices(ctrl:MatSelect) {    
    // ctrl.options.forEach(x=>x.select());
    // this.examsFilterForm.get('devices')?.reset(null);
  }

  onEndDateChanged(event:any) {

    let endDate = event.value;
    let startDate = this.studiesFilterForm.get('dateRange')?.get('transactionStartDate')?.value;

    if (endDate == null) {
      if (startDate == null) {
        // this.dateRange$.next([startDate, endDate]);
      }
    }
    else {
      if (startDate != null) {

        let newEndDate = new Date(endDate);
        let modifiedDate = newEndDate.setDate(newEndDate.getDate() + 1);
        //we add one day to the endDate if not null so that the caculation take into account the whole last day.
        // this.dateRange$.next([startDate, new Date(modifiedDate)]);
      }
    }

  }

  resetFilter() {
    this.studiesFilterForm.reset({
      'freeText': null,
      'devices': null,
      'dateRange': {
        'fromDate': null,
        'toDate': null
      }
    });

    // this.studiesTableService.updateFilter(this.generateFilterModel());
  }

  submitSearchForm() {
    this.studiesTableService.updateFilter(this.generateFilterModel());
  }

  private generateFilterModel() {
    var formValues = this.studiesFilterForm.getRawValue();

    var searchStudiesParams = new GixamStudiesSearchParams();

    searchStudiesParams.freeText = formValues.freeText;

    if (formValues.devices && formValues.devices?.length)
      searchStudiesParams.devices = ((formValues.devices) as any[]).map((x: any) => x.id);
    else{
      searchStudiesParams.devices = formValues.devices;
    }
    if (formValues.dateRange?.fromDate)
      searchStudiesParams.fromDate = new Date(moment(formValues.dateRange.fromDate).format('YYYY-MM-DDThh:mm:ssZ'));
    else {
      searchStudiesParams.fromDate = formValues.dateRange?.fromDate;
    }
    if (formValues.dateRange.toDate)
      searchStudiesParams.toDate = new Date(moment(formValues.dateRange?.toDate).format('YYYY-MM-DDThh:mm:ssZ'));
    else {
      searchStudiesParams.toDate = formValues.dateRange?.toDate;
    }
    searchStudiesParams.pageNumber = 0;

    return searchStudiesParams;
  }

  clearDevicesList(event: MouseEvent){
    event.stopPropagation();

    this.studiesFilterForm.get('devices')?.setValue(null);
  }

  clearFreeTextField(event:MouseEvent){
    event.stopPropagation();

    this.studiesFilterForm.get('freeText')?.setValue(null);
  }
}
