import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'gixam-page-title',
  templateUrl: './gixam-page-title.component.html',
  styleUrls: ['./gixam-page-title.component.scss']
})
export class GixamPageTitleComponent {

  @Input() pageTitle = '';
  @Input() showBackButton: boolean = false;

  constructor(private router: Router) {

  }

  goBack() {
    history.back();
  }

}
