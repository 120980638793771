import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AccountService } from 'src/app/account/account.service';
import { JubaanStorageService } from 'src/app/services/jubaan-storage.service';

@Component({
  selector: 'jubaan-header',
  templateUrl: './jubaan-header.component.html',
  styleUrls: ['./jubaan-header.component.scss']
})
export class JubaanHeaderComponent {

  isAuthenticated:boolean = false;

  constructor(private accountService: AccountService,
    private router:Router,
    private activatedRouter:ActivatedRoute,
     private storageService:JubaanStorageService) {
  }

  ngOnInit(){
    // this.accountService.isUserAuthenticated()
    // .then(result => this.isAuthenticated = result);
    // this.isAuthenticated = this.accountService.isUserAuthenticated();
  }

  login = ()=>{
    this.accountService.login();
  }
  
  signup = ()=>{
   this.accountService.signup();
  }

  logout = ()=>{

    this.accountService.signout();

    // this.accountService.logout()
    // .subscribe(res=>{ });

    //   this.storageService.clearSession();
    //   this.storageService.clearLocal();
    //   this.router.navigate(['/site']);
  
    //   this.accountService.logout();
  }
  
  // navigateToLogin() {

  //   var loginModel = {
  //     clientId:'GixamAngularClient',
  //     grantType: 'code',
  //     redirectUri: 'https://localhost:4200/account/login',
  //     responseType: 'code',
  //     scope: 'openid, email, GixamAPI',
  //     codeChallenge: '',
  //     codeChallengeMethod: 'S256',
  //     codeVerifier: '',
  //     code:'',
  //     clientSecret:''

      
  //   }


  //   this.router.navigate(['account','login']);
  // }

  // isLoggedIn() : Observable<boolean>{
  //   return of(this.accountService.isAuthenticated);
  // }

  // userFullName():string | null{
  //   return this.accountService.fullName;
  // }

  // logout(){
  //   this.accountService.logout()
  //   .subscribe(res=>{ });

  //   this.storageService.clearSession();
  //     this.storageService.clearLocal();
  //     this.router.navigate(['/site']);
  // }
}
