import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GixamStudiesTableService } from './gixam-studies-table.service';
import { GixamStudiesTableDataSource } from './gixam-studies-table-data-source';
import { GixamStudiesDataService } from 'src/app/services/gixam-exams-data.service';
import { FormControl } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Subscription, debounceTime, map, shareReplay } from 'rxjs';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MatDialog } from '@angular/material/dialog';

import { GixamFileUploadComponent } from '../../../gixam/studies/studies-table/gixam-file-upload/gixam-file-upload.component';
import { GixamFilesService } from 'src/app/services/gixam-file-upload.service';
import { environment } from 'src/environments/environment';
import { GixamSnackbarService } from 'src/app/services/gixam-snackbar.service';
import { Sort } from '@angular/material/sort';
import { GixamEditStudyContactDetailsDialogComponent } from 'src/app/gixam/studies/edit-study-contact-details-dialog/gixam-edit-study-contact-details-dialog.component';

@Component({
  selector: 'gixam-studies-table',
  templateUrl: './gixam-studies-table.component.html',
  styleUrls: ['./gixam-studies-table.component.scss']
})
export class GixamStudiesTableComponent {
  
  @ViewChild(MatPaginator) paginator: MatPaginator;
  
  amountPerPageOptions = [10, 20, 50, 100];
  perPageControl = new FormControl<number |null>(this.amountPerPageOptions[0]);
  perPageChanged$ = this.perPageControl.valueChanges.pipe(debounceTime(200), map(value => +value!));

  studiesTableDataSource = new GixamStudiesTableDataSource(this.studiesDataService,this.studiesTableService, this.loaderService);

  searchOptions$ = this.studiesTableService.searchOptions$;
  
  pageRowsCount$ = this.studiesTableDataSource.pageRows$.pipe(map(rows => rows ? rows.length : 0), shareReplay(1));
  
  totalRowsCount$ = this.studiesTableDataSource.totalRowsCount$;

  // isLoading$ = this.studiesTableDataSource.isLoading$;
  isProduction = environment.production;

  displayedColumns:string[] = ['studyDate', 'studyNumber', 'patientNumber', 'deviceName', 'actions'];
  isSyncing = false
  subscription: Subscription;
  
  constructor(private router:Router,
    private activatedRoute:ActivatedRoute, 
    private dialog: MatDialog,
    private loaderService:NgxUiLoaderService,
    private studiesDataService: GixamStudiesDataService,
    private studiesTableService:GixamStudiesTableService,
    private fileUploadService:GixamFilesService,
    private snackbarService:GixamSnackbarService
    ){}


ngOnInit(){
  // this.studiesTableService.updatePageSize(this.amountPerPageOptions[0]);

  // this.perPageChanged$.pipe().subscribe(
  //   pageSize => {
  //     this.studiesTableService.updatePageSize(pageSize);
  //   });
}


  goToExam(studyId:any){
    this.router.navigate(['../exams/' + studyId],{relativeTo:this.activatedRoute});
  }

  trackByFunction(index: number, item: any) {
    return item.id;
  }

  pageChange(event: PageEvent) {
    this.studiesTableService.updatePageNumber(event.pageIndex);
  }

  editPatientDetails(event:MouseEvent, study:any){
    event.stopPropagation();
    
    this.dialog.open(GixamEditStudyContactDetailsDialogComponent,{
      data:{
        patient:study.patient
      }
    })
    .afterClosed()
    .subscribe(updatedPatient=>{
      if (updatedPatient) {
        this.snackbarService.showGeneralMessage("Patient Updated successfully.");
        study.patient = updatedPatient;
      }
    });
  }

  importZip() {
    this.dialog.open(GixamFileUploadComponent,
      {
        disableClose: true        
      })      
  }

  // startStorageSyncing(){
  // this.isSyncing = true
  //  this.subscription = this.fileUploadService.syncWithStorage()    
  //   .subscribe({
  //     complete: ()=> this.isSyncing = false,
  //     error: (error)=> this.isSyncing = false
  //   });
  // }

  // stopStorageSyncing(){
  // this.subscription.unsubscribe();
  // this.isSyncing = false
  // }

  studiesSortChanged(sortState: Sort){
    
    this.studiesTableService.updateSortColumn(sortState.active,sortState.direction);
  }
}
