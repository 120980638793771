import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { LayoutModule } from './layout/layout.module';

import { ErrorHandlingInterceptor } from './intercaptors/error-handling.interceptor';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { SharedModule } from './shared/modules/shared/shared.module';
import { ApiTokenInterceptor } from './intercaptors/api-token.interceptor';
import { NgxUiLoaderModule, POSITION, SPINNER } from 'ngx-ui-loader';
import { Location, LocationStrategy, PathLocationStrategy } from "@angular/common";
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { GixamCustomTranslationLoader } from './translate/gixam-custom-translation-loader';
import { GixamMissingTranslationHandler } from './translate/gixam-missing-translation-handler';
import { JubaanLanguagesService } from './services/jubaan-languages.service';
import { GixamPrivacyPolicyComponent } from './gixam-privacy-policy/gixam-privacy-policy.component';
import { CheckIdComponent } from './gixam/check-id/check-id.component';
import { GixamOrdersTableComponent } from './shared/components/gixam-orders-table/gixam-orders-table.component';

export function tokenGetter() {
  return sessionStorage.getItem('token');
}

@NgModule({
  declarations: [
    AppComponent,
    UnauthorizedComponent,
    NotFoundComponent,
    GixamPrivacyPolicyComponent,
    CheckIdComponent,
    GixamOrdersTableComponent    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      defaultLanguage: 'us',
      useDefaultLang: true,
      loader: {provide: TranslateLoader, useClass: GixamCustomTranslationLoader, deps:[JubaanLanguagesService]},
      missingTranslationHandler: {provide: MissingTranslationHandler, useClass: GixamMissingTranslationHandler},
    }),
    LayoutModule,
    NgxUiLoaderModule.forRoot({
      overlayColor: 'rgba(255,255,255,.8)',
      blur:15,      
      fgsType: SPINNER.rectangleBouncePulseOutRapid,
      fgsColor: '#0184BA',
      fgsSize: 50,
      fgsPosition: POSITION.centerCenter,
      fastFadeOut:true 
    }),   
    SharedModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ApiTokenInterceptor, multi: true },    
    { provide: HTTP_INTERCEPTORS, useClass: ErrorHandlingInterceptor, multi: true },
    Location,
    { provide: LocationStrategy, useClass: PathLocationStrategy },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

}
