import { Injectable } from '@angular/core';
import * as signalR from "@microsoft/signalr"
import { HttpClient } from '@microsoft/signalr';
import { hu } from 'date-fns/locale';
import { BehaviorSubject, Observable, Subject, from, of, switchMap } from 'rxjs';
import { AccountService } from 'src/app/account/account.service';
import { deviceCommandSignalrReply, syncCloudProgressReply } from 'src/app/gixam/device/gixam-device.service';
import { GixamDevice } from 'src/app/models/gixam-device.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DeviceHubService {

  private hubConnection: signalR.HubConnection;

  private _connectionId: string;

  get connectionId() {
    return this._connectionId;
  }


  private _deviceCommandRemoved$ = new Subject<string>();
  deviceCommandRemoved$ = this._deviceCommandRemoved$.asObservable();

  private _deviceCommandProcessed$ = new Subject<string>();
  deviceCommandProcessed$ = this._deviceCommandProcessed$.asObservable();


  // private _commandAction$ = new Subject<any>();
  // public commandAction$ = this._commandAction$.asObservable();

  private _deviceUpdated$ = new Subject<GixamDevice>();
  public deviceUpdated$ = this._deviceUpdated$.asObservable();


  private _deviceListItemUpdated$ = new Subject<any>();
  public deviceListItemUpdated$ = this._deviceListItemUpdated$.asObservable();

  private _cloudSyncProgress$ = new Subject<syncCloudProgressReply>();
  public cloudSyncProgress$ = this._cloudSyncProgress$.asObservable();

  private _liveSessionProgress$ = new Subject<string>();
  public liveSessionProgress$ = this._liveSessionProgress$.asObservable();



  constructor() { }

  isConnected(): boolean {
    return this.hubConnection?.state === signalR.HubConnectionState.Connected;
  }

  public initHub = (user:string) => {

    return new Observable<void>(observer => {

      this.hubConnection = new signalR.HubConnectionBuilder()
        .withAutomaticReconnect()
        .withStatefulReconnect()
        .configureLogging(environment.production? signalR.LogLevel.None : signalR.LogLevel.Debug)
        .withUrl(`${environment.apiUrl}deviceCommands?u=${user}`, {
          skipNegotiation: true,
          transport: signalR.HttpTransportType.WebSockets          
        })
        .build()

        observer.next();
        observer.complete();
    });    
  }



  public closeConnection = () => {

    return new Observable<void>((observer) => {
      this.hubConnection
        .stop()
        .then(() => {

          this._connectionId = "";


          observer.next();
          observer.complete();
        })
        .catch(err => {

          observer.error(err);
          observer.complete();
        });
    });
  }

  public startConnection = () => {   
    return new Observable<void>((observer) => {
      this.hubConnection
        .start()
        .then(() => {

          
          this.hubConnection.invoke('GetConnectionId')
            .then(connId => {          
              this._connectionId = connId;
            });



          observer.next();
          observer.complete();
        })
        .catch(err => {
          console.error('Connection failed. Retrying in 5 seconds...', err);
          setTimeout(() => {
            this.startConnection();
          }, 5000);
          // observer.error(err);
        });
    });

  }

  attachDeviceUpdateEvent() {
    this.hubConnection.on('updateDevice', (device) => {
      this.broadcastDeviceUpdate(device);
    });

    this.hubConnection.on('updateDeviceListItem', (device) => this.broadcastDeviceListItemUpdate(device));

    this.hubConnection.on('commandProcessed', (commandId:string) => {
      if (commandId) {
        this.broadcastDeviceCommandProcessed(commandId);
      }
    });

    //  this.hubConnection.on('commandAdded', (data) => this.broadcastCommandAction({ action: 'add', command: data }));

    this.hubConnection.on('livesession', (blobData) => this.broadcastLiveSession(blobData));
  }

  dispatchDeviceUpdateEvent() {
    this._deviceUpdated$.complete();
    this._deviceListItemUpdated$.complete();
    this._deviceCommandProcessed$.complete();
    this._deviceCommandRemoved$.complete();
    this._liveSessionProgress$.complete();
    
    this.hubConnection.off('updateDevice');
    this.hubConnection.off('updateDeviceListItem');
    
    this.hubConnection.off('commandProcessed');
    this.hubConnection.off('commandAdded');

    
    this.hubConnection.off('livesession');
  }

  attchCloudSyncEventListeners() {

    this.hubConnection.on('syncCloudProgress', (syncCloudProgressData: syncCloudProgressReply) => {
      this.broadcastSyncCloud(syncCloudProgressData);
    });
  }

  dispatchCloudSyncEventListeners() {
    this._cloudSyncProgress$.complete();
    this.hubConnection.off('syncCloudProgress');
  }

  joinDeviceChannel(deviceSerial: string) {
    return of(this.hubConnection.invoke('JoinToDeviceGroup', deviceSerial));
  }

  leaveDeviceChannel(deviceSerial: string) {
    return of(this.hubConnection.invoke('RemoveFromDeviceGroup', deviceSerial));
  }

  // broadcastCommandAction(data: any) {
  //   this._commandAction$.next(data);
  // }

  broadcastDeviceCommandProcessed(commandId: string) {
    this._deviceCommandProcessed$.next(commandId);
  }

  broadcastDeviceCommandRemoved(commandId:string){
    this._deviceCommandRemoved$.next(commandId);
  }

  
  broadcastDeviceUpdate(data: any) {
    this._deviceUpdated$.next(data);
  }

  broadcastDeviceListItemUpdate(data: any) {
    this._deviceListItemUpdated$.next(data);
  }
  broadcastSyncCloud(data: any) {
    this._cloudSyncProgress$.next(data);
  }

  broadcastLiveSession(data: string) {
    this._liveSessionProgress$.next(data);
  }

  broadcastMouseCoordinates(deviceSerial: string, x: string, y: string) {
    this.hubConnection.invoke('GixamMouse', deviceSerial, x, y);
  }

  broadcastKeys(deviceSerial: string, key: string, alt: boolean, shift: boolean, ctrl: boolean) {
    this.hubConnection.invoke("GixamKeyboard", deviceSerial, key, alt, shift, ctrl);
  }
}
