import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class GixamSnackbarService {

  constructor(private snackBar: MatSnackBar) {}

  showGeneralMessage(message: string, durationSec = 2, extraPanelClass: string | null = null) {
    const config = new MatSnackBarConfig();
    
    config.verticalPosition = 'bottom';
    config.duration = durationSec * 1500;
    config.horizontalPosition = 'center';
    
    const classes = ['general-message-snackbar'];
    
    if (extraPanelClass && extraPanelClass.length > 0) {
      classes.push(extraPanelClass);
    }

    config.panelClass = classes;
    this.snackBar.open(message, 'OK', config);
  }
}
