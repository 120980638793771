import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, of, shareReplay, switchMap, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LoginRequestModel } from '../models/LoginRequest.model';
import { LoginResponseModel } from '../models/Response/gixam-login-response.model';
import { UserRegistrationModel } from '../models/UserRegistration.model';
import { GixamUserModel } from '../models/gixam-user.model';

@Injectable({
  providedIn: 'root'
})
export class GixamAccountDataService {

  private endpointPath = of(environment.apiUrl + 'api/users');

  constructor(private httpClient:HttpClient) {

   }

   public login(model:LoginRequestModel): Observable<LoginResponseModel>{
    return this.endpointPath.pipe(
      switchMap(endpointBase => this.httpClient.post<LoginResponseModel>(endpointBase + 'login', model)),
      shareReplay(),
      catchError(err => throwError(()=> new Error(err))));
   }

   public Register(model:UserRegistrationModel): Observable<string>{
    return this.endpointPath.pipe(
      switchMap(endpointBase => this.httpClient.post<string>(endpointBase + 'registration', model)),
      shareReplay(),
      catchError(err => throwError(()=> new Error(err))));
   }

   public confirmEmail(email:string, token:string): Observable<boolean>{
    return this.endpointPath.pipe(
      switchMap(endpointBase => this.httpClient.get<boolean>(endpointBase + 'email/verify',
      { params:
        {
          email:email,
          token:token
        }
      })),
      catchError(err => throwError(()=> new Error(err))));

   }

   public sendEmailConfirmation(email:string): Observable<boolean>{
    return this.endpointPath.pipe(
      switchMap(endpointBase => this.httpClient.get<boolean>(endpointBase + 'email/confirmation',{ params: { email: email }})),
      catchError(err => throwError(()=> new Error(err))));
   }

   public onboarding(onboardingModel:any){
    return this.endpointPath.pipe(
      switchMap(endpointBase => this.httpClient.post<boolean>(endpointBase + 'onboarding',onboardingModel)),
      catchError(err => throwError(()=> new Error(err))));
   }

   public logout(){
    return this.endpointPath.pipe(
      switchMap(endpointBase => this.httpClient.post<boolean>(`${endpointBase}/logout`,{})),
      catchError(err => throwError(()=> new Error(err))));
   }

   public createUser(model:any){
    return this.endpointPath.pipe(
      switchMap(endpoint=> this.httpClient.post<string>(`${endpoint}/create`, model)),
      catchError(err => throwError(()=> new Error(err))));
  }
  
   public getUser(memberId:string){
    return this.endpointPath.pipe(
      switchMap(endpointBase => this.httpClient.get<any>(`${endpointBase}/get`, {params:{id:memberId}})),
        catchError(err => throwError(()=> new Error(err))));
    
  }

  public processPostLogin(memberId:string){

    var model = {
      applicationId: environment.clientId,
      memberId:memberId,
      languageId:null
    }

    return this.endpointPath.pipe(
      switchMap(endpointBase => this.httpClient.post<any>(`${endpointBase}/postLogin`, model)),
        catchError(err => throwError(()=> new Error(err))));

  }
 
  public getInvitationUser(invitationEmail:string){
    return this.endpointPath.pipe(
      switchMap(endpointBase => this.httpClient.get<any>(`${endpointBase}/invitationUser`, {params:{invitationEmail:invitationEmail}})),
        catchError(err => throwError(()=> new Error(err))));
  }
}
