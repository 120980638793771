import { HttpEventType } from '@angular/common/http';
import { Component } from '@angular/core';
import { Subscription, zip } from 'rxjs';
import { GixamFilesService } from 'src/app/services/gixam-file-upload.service';

@Component({
  selector: 'gixam-file-upload',
  templateUrl: './gixam-file-upload.component.html',
  styleUrls: ['./gixam-file-upload.component.scss']
})
export class GixamFileUploadComponent {

  uploadPercentages: number = 0;
  uploadedSize:string;
  totalSize:String;
  fileName:string | null;

  selectedFile?:File;

  subscription: Subscription;
  showImportResult: Boolean = false;
  
  constructor(private filesService: GixamFilesService) { }

  onFileSelected(event: any) {

    this.uploadPercentages = 0;    

    this.selectedFile = event.target.files[0];
    this.totalSize = this.formatUploadedSize(this.selectedFile!.size);
    this.fileName = this.selectedFile?.name || null;

    if (this.selectedFile) {

      const formData = new FormData();

      formData.append('request', this.selectedFile);

      this.subscription = this.filesService.uploadZipFile(formData)
        .subscribe((progress: any) => {
         
          event.target.value = null;
          if (progress.type == HttpEventType.UploadProgress) {
            this.uploadPercentages = Math.round(parseInt(progress.loaded) / parseInt(progress.total) * 100);
            this.uploadedSize = this.filesService.formatFileSize(progress.loaded);
          }
           
          if (progress.type == HttpEventType.Response){
            this.showImportResult = true;
          }
        });


    }
  }

  formatUploadedSize(totalUploaded:number){
    return this.filesService.formatFileSize(totalUploaded);
  }

  cancelUpload() {
    
    this.subscription.unsubscribe();    
    this.selectedFile = undefined;
    this.uploadPercentages = 0;
  }
}
