import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { SharedMaterialModule } from '../shared-material/shared-material.module';

import { GixamPageTitleComponent } from '../../components/gixam-page-title/gixam-page-title.component';
import { GixamDevicesTableFilterComponent } from '../../components/gixam-devices-table-filter/gixam-devices-table-filter.component';
import { GixamStudiesTableFilterComponent } from '../../components/gixam-studies-table-filter/gixam-studies-table-filter.component';
import { GixamDevicesTableComponent } from '../../components/gixam-devices-table/gixam-devices-table.component';
import { GixamStudiesTableComponent } from '../../components/gixam-studies-table/gixam-studies-table.component';
import { GixamFileUploadComponent } from '../../../gixam/studies/studies-table/gixam-file-upload/gixam-file-upload.component';
import { GixamLoaderComponent } from '../../components/gixam-loader/gixam-loader.component';
import { RouterModule } from '@angular/router';
import { CreateClinicFormComponent } from '../../components/create-clinic-form/create-clinic-form.component';
import { NgxUiLoaderModule, POSITION, SPINNER } from 'ngx-ui-loader';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { GixamCustomTranslationLoader } from 'src/app/translate/gixam-custom-translation-loader';
import { JubaanLanguagesService } from 'src/app/services/jubaan-languages.service';
import { GixamMissingTranslationHandler } from 'src/app/translate/gixam-missing-translation-handler';
import { GixamStripeComponent } from '../../components/gixam-stripe/gixam-stripe.component';
import { NgxStripeModule } from 'ngx-stripe';
import { environment } from 'src/environments/environment';

@NgModule({
  declarations: [
    GixamStudiesTableComponent,
    GixamDevicesTableComponent,
    GixamPageTitleComponent,
    GixamDevicesTableFilterComponent,
    GixamStudiesTableFilterComponent,
    GixamFileUploadComponent,
    GixamLoaderComponent,
    CreateClinicFormComponent,
    GixamStripeComponent
  ],
  imports: [
    CommonModule,
    RouterModule,    
    ReactiveFormsModule,
    TranslateModule.forChild({
      extend: true,
      loader: {provide: TranslateLoader, useClass: GixamCustomTranslationLoader, deps:[JubaanLanguagesService]},
      missingTranslationHandler: {provide: MissingTranslationHandler, useClass: GixamMissingTranslationHandler},
    }),
    FormsModule,
    HttpClientModule,
    SharedMaterialModule,
    NgxUiLoaderModule,
    NgxStripeModule.forRoot(environment.stripeKey)
  ],
  exports:[
    CommonModule,
    RouterModule,
    TranslateModule,
    SharedMaterialModule,
    ReactiveFormsModule,
    FormsModule,
    NgxUiLoaderModule,
    NgxStripeModule,
    GixamStudiesTableComponent,
    GixamDevicesTableComponent,
    GixamPageTitleComponent,
    GixamDevicesTableFilterComponent,
    GixamStudiesTableFilterComponent,
    GixamFileUploadComponent,
    GixamLoaderComponent,
    CreateClinicFormComponent,
    GixamStripeComponent  
  ]
})
export class SharedModule { }
