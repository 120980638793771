import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { GixamCountryModel } from 'src/app/models/gixam-country.model';
import { GixamCountriesDataService } from 'src/app/services/gixam-countries-data.service';
import { phoneNumberValidator } from 'src/app/validators/phone-number-validator';

@Component({
  selector: 'contact-us-form',
  templateUrl: './contact-us-form.component.html',
  styleUrls: ['./contact-us-form.component.scss']
})
export class ContactUsFormComponent {
  countries$: Observable<GixamCountryModel[]> = this.countriesDataService.getAllCountries();


  public contactUsForm = new FormGroup({
    name: new FormControl<string | null>(null, [Validators.required, Validators.maxLength(30)]),
    email: new FormControl<string | null>(null, [Validators.required, Validators.email]),
    phone: new FormGroup({
      countryCode: new FormControl<string | null>(null),
      phoneNumber: new FormControl<string | null>(null),
    }, { updateOn: 'blur', validators: phoneNumberValidator }), descriotion: new FormControl<string | null>(null, [Validators.required]),
    message: new FormControl<string | null>(null, [Validators.required, Validators.maxLength(300)])
  });

  constructor(private countriesDataService: GixamCountriesDataService){}
}
