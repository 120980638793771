import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DeviceRegistrationModel } from '../models/gixam-device-registration.model';
import { Observable, catchError, map, of, switchMap, tap, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GixamDevicesSearchParams } from '../models/GixamDevicesSearchParams.model';
import { GixamDevice, GixamDeviceUpdateModel } from '../models/gixam-device.model';
import { GixamDeviceCommand } from '../models/gixam-device-command';
import { GixamCreateDeviceInvitationModel } from '../models/gixam-create-device-invitation-model';

@Injectable({
  providedIn: 'root'
})
export class GixamDevicesDataService {

  private _endpointBasePath$ = of(environment.apiUrl + 'api/devices');
  private _deviceInvitationEndpointBasePath$ = of(environment.apiUrl + 'api/devices/invitations');
  private liveSessionEndpointBasePath$ = of(environment.apiUrl + "api/live-session");

  constructor(private httpClient: HttpClient
  ) { }

  public registerDevice(deviceRegistrationModel: DeviceRegistrationModel): Observable<any> {

    return this._endpointBasePath$.pipe(
      switchMap((endpointBasePath: string) => this.httpClient.post<any>(`${endpointBasePath}/register`, deviceRegistrationModel)),
      catchError(err => throwError(() => new Error(err))));
  }

  public getOperatorDevices(): Observable<any> {
    return this._endpointBasePath$.pipe(
      switchMap((endpointBasePath: string) => this.httpClient.get<any>(`${endpointBasePath}/by-operator`)),
      catchError(err => throwError(() => new Error(err))));
  }

  public getOwnedDevices() { }

  public searchDevices(searchParams: GixamDevicesSearchParams): Observable<any> {
    return this._endpointBasePath$.pipe(
      switchMap((endpointBasePath: string) => this.httpClient.post<any>(`${endpointBasePath}/search`, searchParams)),
      catchError(err => throwError(() => new Error(err))));
  }

  public getDevice(deviceId: string): Observable<any> {
    return this._endpointBasePath$.pipe(
      switchMap((endpointBasePath: string) => this.httpClient.get<any>(`${endpointBasePath}/${deviceId}`)),
      catchError(err => throwError(() => new Error(err)))
    );
  }

  public getDeviceBySerial(deviceSerial: string): Observable<any> {
    return this._endpointBasePath$.pipe(
      switchMap((endpointBasePath: string) => this.httpClient.get<any>(`${endpointBasePath}/get-by-serial/${deviceSerial}`)),
      catchError(err => throwError(() => new Error(err)))
    );
  }

  public updateDevice(deviceId: number, gxmDevice: GixamDeviceUpdateModel): Observable<GixamDevice> {
    return this._endpointBasePath$.pipe(
      switchMap((endpointBasePath: string) => this.httpClient.put<GixamDevice>(`${endpointBasePath}/${deviceId}/update`, gxmDevice)),
      catchError(err => throwError(() => new Error(err))));
  }

  public purchaseDevice(purchaseDeviceModel: any): Observable<any> {
    return this._endpointBasePath$.pipe(
      switchMap((endpointBasePath: string) => this.httpClient.post<any>(endpointBasePath + '/purchase', purchaseDeviceModel)),
      catchError(err => throwError(() => new Error(err))));
  }


  // public getDeviceCommands(deviceId: string) {
  //   return this._endpointBasePath$.pipe(
  //     switchMap((endpointBasePath: string) => this.httpClient.get<GixamDeviceCommand[]>(`${endpointBasePath}/${deviceId}/commands/active`)),
  //     catchError(err => throwError(() => new Error(err))));
  // }

  // public getUnknownDeviceCommands(deviceId: string) {
  //   return this._endpointBasePath$.pipe(
  //     switchMap((endpointBasePath: string) => this.httpClient.get<GixamDeviceCommand[]>(`${endpointBasePath}/${deviceId}/commands/unknown`)),
  //     catchError(err => throwError(() => new Error(err))));
  // }

  // public AddDeviceCommand(deviceId: string, commandName: string, connectionId: string) {

  //   var model = {
  //     commandName: commandName,
  //     connectionId: connectionId
  //   };

  //   return this._endpointBasePath$.pipe(
  //     switchMap((endpointBasePath: string) => this.httpClient.post<any>(`${endpointBasePath}/${deviceId}/commands/add`, model)),
  //     catchError(err => throwError(() => new Error(err))));
  // }

  // public UpdateCommandsPosition(deviceId: string, commands: GixamDeviceCommand[], connectionId: String) {

  //   var model = {
  //     commands: commands,
  //     connectionId: connectionId
  //   }
  //   return this._endpointBasePath$.pipe(
  //     switchMap((endpointBasePath: string) => this.httpClient.post<any>(`${endpointBasePath}/${deviceId}/commands/commit`, model)),
  //     catchError(err => throwError(() => new Error(err))));
  // }

  


  public GetDeviceInvitations(deviceId: string) {
    return this._endpointBasePath$.pipe(
      switchMap((endpointBasePath: string) => this.httpClient.get<any[]>(`${endpointBasePath}/${deviceId}/invitations`)),
      catchError(err => throwError(() => new Error(err))));
  }

  public InviteToDevice(model: GixamCreateDeviceInvitationModel) {

    return this._endpointBasePath$.pipe(
      switchMap((endpointBasePath: string) => this.httpClient.post<any>(`${endpointBasePath}/${model.deviceId}/invitations/new`, model)),
      catchError(err => throwError(() => new Error(err))));
  }


  public resendDeviceInvitation(deviceId: string, invitationId: string) {
    return this._endpointBasePath$.pipe(
      switchMap((endpointBasePath: string) => this.httpClient.get<any>(`${endpointBasePath}/${deviceId}/invitations/${invitationId}/resend`)),
      catchError(err => throwError(() => new Error(err))));
  }



  public getLiveSessionDeviceSerial(liveSessionKey:string):Observable<string>{
    return this.liveSessionEndpointBasePath$.pipe(
      switchMap((liveSessionEndpointBasePath: string) => this.httpClient.get<any>(`${liveSessionEndpointBasePath}/${liveSessionKey}`)),      
      catchError(err => throwError(() => new Error(err))));
  }


  public getLiveSessionBlob(deviceId: string) {
    return this.liveSessionEndpointBasePath$.pipe(
      switchMap((liveSessionEndpointBasePath: string) => this.httpClient.get<any>(`${liveSessionEndpointBasePath}/${deviceId}/storage`,{responseType: 'blob' as 'json' })),      
      catchError(err => throwError(() => new Error(err))));
  }

  startSession(deviceId:string){
    return this.liveSessionEndpointBasePath$.pipe(
      switchMap((liveSessionEndpointBasePath: string) => this.httpClient.get<any>(`${liveSessionEndpointBasePath}/${deviceId}/startSession`)),      
      catchError(err => throwError(() => new Error(err))));
  }

  stopSession(deviceId:string){
    return this.liveSessionEndpointBasePath$.pipe(
      switchMap((liveSessionEndpointBasePath: string) => this.httpClient.get<any>(`${liveSessionEndpointBasePath}/${deviceId}/stopSession`)),      
      catchError(err => throwError(() => new Error(err))));
  }

  setLiveSessionMouseCorrdinates(deviceId:string, x:string, y:string){

    var jsonData = {
      x: x,
      y:y
    };

    return this.liveSessionEndpointBasePath$.pipe(
      switchMap((liveSessionEndpointBasePath: string) => this.httpClient.post<boolean>(`${liveSessionEndpointBasePath}/${deviceId}/click`,jsonData)),      
      catchError(err => throwError(() => new Error(err))));
  }

  
  
}
