import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { GixamProductsTableService } from "./gixam-products-table.service";
import { BehaviorSubject, Observable, Subject, catchError, distinctUntilChanged, filter, finalize, map, merge, of, shareReplay, switchMap, takeUntil, tap, throwError } from "rxjs";
import { GixamProductsDataService } from "src/app/services/gixam-products-data.service";
import { NgxUiLoaderService } from "ngx-ui-loader";


export class GixamProductsTableDataSource implements DataSource<any> {
    private destroy$ = new Subject<void>();

    private searchOptions$ = this.productsTableService.searchOptions$;
    private refreshData$ = this.productsTableService.refreshData$.pipe(tap(_ => this.productsTableService.updatePageNumber(0)));


    private _totalRowsCount$ = new BehaviorSubject<number>(0);
    public totalRowsCount$ = this._totalRowsCount$.pipe(takeUntil(this.destroy$), distinctUntilChanged(), shareReplay(1));
    private productsSubject = new BehaviorSubject<any[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);

    private _loading$ = new BehaviorSubject<boolean>(false);
    public isLoading$ = this._loading$.pipe(takeUntil(this.destroy$), distinctUntilChanged(), shareReplay(1));

    private loaderName = "product-loader";
    constructor(private productsDataService: GixamProductsDataService,
        private productsTableService: GixamProductsTableService,
        private loaderService: NgxUiLoaderService) { }

    connect(collectionViewer: CollectionViewer): Observable<any> {

        this.loaderService.startLoader(this.loaderName);
        this._loading$.next(true);
        return this.pageRows$.pipe(
            tap(() => this.loaderService.stopLoader(this.loaderName)),
            catchError((error) => {
                this.loaderService.stopLoader(this.loaderName);
                return throwError(() => new Error(error));
            }));
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.productsSubject.complete();
        this.loadingSubject.complete();
    }

    public pageRows$ = merge(this.refreshData$, this.searchOptions$)
        .pipe(
            switchMap(() => this.searchOptions$),
            takeUntil(this.destroy$),
            filter(searchOptions => {
                return searchOptions !== null;
            }),
            tap(() => this.loaderService.startLoader(this.loaderName)),
            switchMap(searchOptions => this.searchProducts(searchOptions)),
            tap(response => {

                this._totalRowsCount$.next(response.results?.count || 1);
            }),
            map(response => response.results?.pagedProducts),
            tap(() => this.loaderService.stopLoader(this.loaderName)),
            shareReplay(1)
        );

    private searchProducts(searchParams: any) {
        return this.productsDataService.searchProducts(searchParams)
        catchError(error => {
            return of([]);
        });
    }

}
