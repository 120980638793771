import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { BehaviorSubject, Observable, Subject, catchError, combineLatest, distinctUntilChanged, filter, finalize, interval, map, merge, of, shareReplay, startWith, switchMap, takeUntil, tap, throwError } from "rxjs";
import { GixamDevicesDataService } from "src/app/services/gixam-devices-data.service";
import { GixamDevicesTableService } from "./gixam-devices-table.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { GixamDeviceService } from "src/app/gixam/devices/device/gixam-device.service";
import { DeviceHubService } from "src/app/services/signalR/device-hub.service";

export class GixamDevicesTableDataSource implements DataSource<any> {

    private hubConnection: signalR.HubConnection;
    private connId: string;

    private destroy$ = new Subject<void>();

    private searchOptions$ = this.devicesTableService.searchOptions$;
    private refreshData$ = this.devicesTableService.refreshData$.pipe(tap(_ => this.devicesTableService.updatePageNumber(0)));

    private deviceListItemUpdated$ = this.deviceHubService.deviceListItemUpdated$.pipe(distinctUntilChanged(), startWith(null));

    private _totalRowsCount$ = new BehaviorSubject<number>(0);
    public totalRowsCount$ = this._totalRowsCount$.pipe(takeUntil(this.destroy$), distinctUntilChanged(), shareReplay(1));
    private devicesSubject = new BehaviorSubject<any[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);

    private _loading$ = new BehaviorSubject<boolean>(false);
    public isLoading$ = this._loading$.pipe(takeUntil(this.destroy$), distinctUntilChanged(), shareReplay(1));

    private loaderName = "devices-loader";
    constructor(private devicesDataService: GixamDevicesDataService,
        private devicesTableService: GixamDevicesTableService,
        private deviceHubService: DeviceHubService,
        private loaderService: NgxUiLoaderService) { }

    connect(collectionViewer: CollectionViewer): Observable<any> {

        this.loaderService.startLoader(this.loaderName);
        this._loading$.next(true);
        return this.pageRows$.pipe(
            tap(() => this.loaderService.stopLoader(this.loaderName)),
            catchError((error) => {
                this.loaderService.stopLoader(this.loaderName);
                return throwError(() => new Error(error));
            }));
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.devicesSubject.complete();
        this.loadingSubject.complete();
    }

    public pageRows$ = merge(this.refreshData$, this.searchOptions$)
        .pipe(
            switchMap(() => this.searchOptions$),
            takeUntil(this.destroy$),
            filter(searchOptions => {
                return searchOptions !== null;
            }),
            tap(() => this.loaderService.startLoader(this.loaderName)),
            switchMap(searchOptions => this.searchDevices(searchOptions)),
            tap(response => {
                this._totalRowsCount$.next(response.results.count || 1);
            }),
            map(response => response.results.pagedDevices),
            tap(() => this.loaderService.stopLoader(this.loaderName)),
            shareReplay(1)
        );
  
    private searchDevices(searchParams: any) {
        return combineLatest([this.devicesDataService.searchDevices(searchParams), this.deviceListItemUpdated$])
            .pipe(map(([devices, updatedDevice]) => {
                if (updatedDevice) {                    
                    var deviceIndex = devices.results.pagedDevices.findIndex((x: any) => x.id == updatedDevice.id);                    
                    devices.results.pagedDevices[deviceIndex] = updatedDevice;
                }

                devices.results.pagedDevices.forEach((x: any) => {

                    if (x.lastPing) {
                        if (!x.lastPing?.endsWith('Z')) {
                            x.lastPing = x.lastPing + 'Z';
                        }

                        var diff = (new Date().getTime() - new Date(x.lastPing).getTime());

                        x.isDeviceOnline = diff < 90000;
                    }
                    else{
                        x.isDeviceOnline = false;
                    }
                });

                devices.results.pagedDevices = [...devices.results.pagedDevices];
                return devices;
            }),
                catchError(error => {
                    return of([]);                    
                }));

    }


    isOnline$ = interval(60000)
        .pipe(
            switchMap((_) => this.pageRows$),
            map(devices => {
                if (devices) {
                    devices.forEach((x: any) => {

                        if (x.lastPing) {
                            if (!x.lastPing?.endsWith('Z')) {
                                x.lastPing = x.lastPing + 'Z';
                            }

                            var diff = (new Date().getTime() - new Date(x.lastPing).getTime());

                            x.isDeviceOnline = diff < 90000;
                        }
                        else{
                            x.isDeviceOnline = false;
                        }

                        
                    });
                }

                return devices;
            })
        )
}
