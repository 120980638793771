import { AbstractControl } from "@angular/forms";
import { PhoneNumberUtil, PhoneNumberType, PhoneNumber} from 'google-libphonenumber';
import { GixamCountryModel } from "../models/gixam-country.model";



export function phoneNumberValidator(control: AbstractControl): {[key:string]:string} | null {

    const phoneNumberUtil = PhoneNumberUtil.getInstance();

        const countryFormControl = control.get('countryCode');
        const phoneNumberFormControl = control.get('phoneNumber');        
        let PNT = PhoneNumberType;

        let countryValue = countryFormControl?.value as GixamCountryModel;
        let phoneNumberValue:string = phoneNumberFormControl?.value

        if (!countryValue){

            if (phoneNumberValue){
                phoneNumberFormControl?.setErrors({invalidPhoneNumber: 'Select country code'});
                return {invalidPhoneNumber: 'Select country code'}; 
            }

            return null;
        }
        
        if (countryValue instanceof(GixamCountryModel) == false){
            phoneNumberFormControl?.setErrors({invalidPhoneNumber: 'Please select country dailing code'});
            return {invalidPhoneNumber: 'Please select country dailing code'}; 
        }
                                            
        PhoneNumber.CountryCodeSource.FROM_NUMBER_WITH_PLUS_SIGN;
             
        try{

            if (!phoneNumberValue){
                phoneNumberFormControl?.setErrors({ invalidPhoneNumber :'Invalid phone number'});
                return { invalidPhoneNumber :'Invalid phone number'};
            }

            if (isNaN(+phoneNumberValue)){
                phoneNumberFormControl?.setErrors({ invalidPhoneNumber :'Phone number must contain only digits'});
                return {invalidPhoneNumber:'Phone number must contain only digits'};
            }

            let libPhoneNumber = phoneNumberUtil.parse(phoneNumberValue, countryValue.code);
            let phoneNumberType = phoneNumberUtil.getNumberType(libPhoneNumber);

            if (!phoneNumberUtil.isValidNumber(libPhoneNumber)){
                phoneNumberFormControl?.setErrors({ invalidPhoneNumber :'Invalid phone number'});
                return { invalidPhoneNumber :'Invalid phone number'};
            }

            if (phoneNumberType > 2){
                phoneNumberFormControl?.setErrors({ invalidPhoneNumber :'Phone number type is not suppported'});
                return { invalidPhoneNumber :'Phone number type is not suppported'};
            }
        
            if (!phoneNumberUtil.isValidNumberForRegion(libPhoneNumber,countryValue.code)){
                phoneNumberFormControl?.setErrors({ invalidPhoneNumber :`Invalid phone number for ${countryValue.name}`});
                return {invalidPhoneNumber: `Invalid phone number for ${countryValue.name}`};
            }
            
            phoneNumberFormControl?.setErrors(null);
            return null;
        }
        catch{
            phoneNumberFormControl?.setErrors({ invalidPhoneNumber :'Cannot parse phone number'});
            return {invalidPhoneNumber:'Cannot parse phone number'};
        }      
}
// export function phonNumberValidatior(country: GixamCountryModel): ValidatorFn {

//     return (control: AbstractControl) : ValidationErrors | null => {
        
//         if (control instanceof(FormGroup)){

//             const countryCode = control.get('countryCode');
//             const phoneNumber = control.get('phoneNumber');
                    
//             var t = countryCode?.value + phoneNumber?.value;
            
//             phoneUtil.PhoneNumber.CountryCodeSource.FROM_NUMBER_WITH_PLUS_SIGN;
                    
//             var phoneUtils = phoneUtil.PhoneNumberUtil.getInstance();
//             phoneUtils.parse(t, )
    
//             return {validPhoneNumber:true};
//         }
//         else{
//             return {noFormGroup:true};
//         }
//     }

// }