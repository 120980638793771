import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Route, Router } from '@angular/router';
import { AccountService } from '../account/account.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlingInterceptor implements HttpInterceptor {

  constructor(private router: Router, private accountService:AccountService) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req)
      .pipe(
        catchError((error: HttpErrorResponse) => {

          if (error.error instanceof ErrorEvent) {
            console.log("Client Side Error Raised.");
            console.log(error.error.message);
          }
          else{  
            console.log("Server  Side Error Occurred.");
            console.log(error.message);
          }
          
          let errorMessage = this.handleError(req, error.error);
          
          return throwError(() => new Error(errorMessage));
        })
      )
  }

  private handleError = (request:HttpRequest<any>, error: HttpErrorResponse): string => {

    switch(error.status){
      case 400:
        return this.handleBadRequest(error);
        break;
        case 401:
          return this.handleUnauthorizedRequest(request, error);
        break;
        case 403:
        break;
        case 404:
          return this.handleNotFound(error);
        break;
        case 500:
        break;
        default:
          break;
    }

    if (error.status === 404) {
      return this.handleNotFound(error);
    }
    else if (error.status === 401) {
      return this.handleUnauthorizedRequest(request, error);
    }
    else if (error.status === 400) {
      return this.handleBadRequest(error);
    }
    else if (!error.status) {
      return error.statusText;
    }

    return "";
  }

  private handleNotFound = (error: HttpErrorResponse): string => {
    this.router.navigate(['/404']);
    return error.message;
  }

  private handleBadRequest = (error: HttpErrorResponse): string => {
    //if(this.router.url === '/account/registration'){
    let message = '';
    const values = Object.values(error.error.errors);

    values.map(m => {
      message += m + '<br>';
    })

    return message.slice(0, -4);
    // }
    // else{
    //   return error.error ? error.error : error.message;
    // }
  }

  private handleUnauthorizedRequest = (request:HttpRequest<any>, error: HttpErrorResponse): string => {

    const redirectUrl = request.urlWithParams;

    this.accountService.login(redirectUrl);

    // this.router.navigate(['unauthorized']);
    return error.message;
  }
}