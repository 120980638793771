import { Injectable } from '@angular/core';
import * as signalR from "@microsoft/signalr"
import { BehaviorSubject, Observable, Subject, of, switchMap } from 'rxjs';
import { deviceCommandSignalrReply, syncCloudProgressReply } from 'src/app/gixam/devices/device/gixam-device.service';
import { GixamDevice } from 'src/app/models/gixam-device.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DeviceHubService {

  private hubConnection: signalR.HubConnection;
  private _connectionId: string;

  get connectionId() {
    return this._connectionId;
  }

  private _commandAction$ = new Subject<any>();
  public commandAction$ = this._commandAction$.asObservable();

  private _deviceUpdated$ = new Subject<GixamDevice>();
  public deviceUpdated$ = this._deviceUpdated$.asObservable();


  private _deviceListItemUpdated$ = new Subject<any>();
  public deviceListItemUpdated$ = this._deviceListItemUpdated$.asObservable();

  private _cloudSyncProgress$ = new Subject<syncCloudProgressReply>();
  public cloudSyncProgress$ = this._cloudSyncProgress$.asObservable();

  constructor() {
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withAutomaticReconnect()
      .withUrl(`${environment.apiUrl}deviceCommands`, {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets
      })
      .build();
  }

  public closeConnection = () => {

    return new Observable<void>((observer) => {
      this.hubConnection
        .stop()
        .then(() => {

          this._connectionId = ""; 
          console.log('Connection closed');

          observer.next();
          observer.complete();
        })
        .catch(err => {
          console.log('Error while closing connection: ' + err)
          observer.error(err);
        });
    });
  }

  public startConnection = () => {

    return new Observable<void>((observer) => {
      this.hubConnection
        .start()
        .then(() => {

          this.hubConnection.invoke('GetConnectionId')
            .then(connId => {
              this._connectionId = connId;
            });

          console.log('Connection started');

          observer.next();
          observer.complete();
        })
        .catch(err => {
          console.log('Error while starting connection: ' + err)
          observer.error(err);
        });
    });

  }

  attachDeviceUpdateEvent() {
    this.hubConnection.on('updateDevice', (device) => {      
      this.broadcastDeviceUpdate(device);
    });

    this.hubConnection.on('updateDeviceListItem', (device) => {      
      this.broadcastDeviceListItemUpdate(device);
    });
    this.hubConnection.on('commandDone', (data: deviceCommandSignalrReply) => {
      if (data) {
        this.broadcastCommandAction({ action: 'delete', command: data.commandSequential });        
      }
    });

    this.hubConnection.on('commandAdded', (data) => {
      this.broadcastCommandAction({ action: 'add', command: data });      
    });
  }

  dispatchDeviceUpdateEvent() {
    this._deviceUpdated$.complete();
    this._deviceListItemUpdated$.complete();

    this.hubConnection.off('updateDevice');
    this.hubConnection.off('updateDeviceListItem');

    this._commandAction$.complete();
    this.hubConnection.off('commandDone');
    this.hubConnection.off('commandAdded');
  }

  attchCloudSyncEventListeners() {

    this.hubConnection.on('syncCloudProgress', (syncCloudProgressData: syncCloudProgressReply) => {
      this.broadcastSyncCloud(syncCloudProgressData);      
    });
  }

  dispatchCloudSyncEventListeners() {
    this._cloudSyncProgress$.complete();
    this.hubConnection.off('syncCloudProgress');
  }

  joinDeviceChannel(deviceSerial: string) {
    return of(this.hubConnection.invoke('JoinToDeviceGroup', deviceSerial));
  }

  leaveDeviceChannel(deviceSerial: string) {
    return of(this.hubConnection.invoke('RemoveFromDeviceGroup', deviceSerial));
  }

  broadcastCommandAction(data:any){
    this._commandAction$.next(data);
  }

  broadcastDeviceUpdate(data:any){
    this._deviceUpdated$.next(data);
  }

  broadcastDeviceListItemUpdate(data:any){
    this._deviceListItemUpdated$.next(data);
  }
  broadcastSyncCloud(data:any){
    this._cloudSyncProgress$.next(data);
  }
}
