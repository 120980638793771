import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, of, shareReplay, switchMap, tap, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GixamCountryModel, GixamStateModel } from '../models/gixam-country.model';

@Injectable({
  providedIn: 'root'
})
export class GixamCountriesDataService {
  private _endpointBasePath$ = of(environment.apiUrl + 'api/countries');

  constructor(private httpClient: HttpClient) { }


  public getAllCountries() {
    return this._endpointBasePath$.pipe(
      switchMap((endpointBasePath: string) => this.httpClient.get<GixamCountryModel[]>(`${endpointBasePath}`)),
      map((countries: any[]) => {
        return countries.map(countryObj => {
          var country = new GixamCountryModel();
          country.id = countryObj.id;
          country.code = countryObj.code;
          country.name = countryObj.name;
          country.countryCode = countryObj.countryCode;
          return country;
        })
      }),
      shareReplay(1),
      catchError(err => throwError(() => new Error(err))));
  }

  getStatesByCountryId(countryId: string) {
    return this._endpointBasePath$.pipe(
      switchMap((endpointBasePath: string) => this.httpClient.get<GixamStateModel[]>(`${endpointBasePath}/${countryId}/states`)),
      map((states: any[]) => {
        return states.map(stateObj => {
          var state = new GixamStateModel();
          state.id = stateObj.id;
          state.code = stateObj.code;
          state.name = stateObj.name;

          return state;
        })
      }),
      shareReplay(1),
      catchError(err => throwError(() => new Error(err))));
  }

  getStatesByCountryCode(code: string) {
    return this._endpointBasePath$.pipe(
      switchMap((endpointBasePath: string) => this.httpClient.get<GixamStateModel[]>(`${endpointBasePath}/${code}/states`)),
      map((states: any[]) => {
        return states.map(stateObj => {
          var state = new GixamStateModel();
          state.id = stateObj.id;
          state.code = stateObj.code;
          state.name = stateObj.name;

          return state;
        })
      }),
      shareReplay(1),
      catchError(err => throwError(() => new Error(err))));
  }  
}
