<form [formGroup]="clinicForm" (submit)="submit()">

    <div class="form-control flex-column">
        <mat-label>Clinic Name</mat-label>
        <mat-form-field appearance="outline">
            <input matInput type="text" formControlName="name" />
            <mat-error *ngIf="clinicForm.get('name')?.hasError('required')">Required Field</mat-error>
        </mat-form-field>
    </div>   

    <div class="form-control flex-column">
        <mat-label>Clinic Email</mat-label>
        <mat-form-field appearance="outline">
            <input matInput type="text" formControlName="email" />
            <mat-error *ngIf="clinicForm.get('email')?.hasError('required')">Required Field</mat-error>
            <mat-error *ngIf="clinicForm.get('email')?.hasError('email')">Invalid email address</mat-error>
        </mat-form-field>
    </div>

    <ng-container formGroupName="phone">
        <div class="form-controls">
            <div class="form-control flex-column flex-half">
                <mat-label>Dailing Code</mat-label>
                <mat-form-field appearance="outline">                    
                    <mat-select formControlName="countryCode" #countryCodeSelect>
                        <mat-select-trigger>
                            <div class="mat-option-inner">
                                <span class="fi fi-{{countryCodeSelect.value?.code.toLowerCase()}}"></span>
                                <span>{{'(' + countryCodeSelect.value?.countryCode + ')'}}</span>                                
                            </div>
                          </mat-select-trigger>
                          <mat-option [value]="null">Select...</mat-option>
                        <mat-option *ngFor="let country of (countries$ |async)" [value]="country">
                            <div class="mat-option-inner">
                                <span class="fi fi-{{country.code.toLowerCase()}}"></span>
                                <span>{{country.name}}</span>
                            </div>
                        </mat-option>
                    </mat-select>
                    
                </mat-form-field>
            </div>

            <div class="form-control flex-column flex-full">
                <mat-label>Phone Number</mat-label>
                <mat-form-field appearance="outline">
                    <input matInput type="text" formControlName="phoneNumber" />                    
                    <mat-error *ngIf="clinicForm.get('phone.phoneNumber')?.hasError('invalidPhoneNumber')">
                        {{clinicForm.get('phone.phoneNumber')?.getError('invalidPhoneNumber')}}
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
    </ng-container>

    <ng-container formGroupName="contact">
        <div class="form-controls">
            <div class="form-control flex-column">
                <mat-label>Administrator First Name</mat-label>
                <mat-form-field appearance="outline">
                    <input matInput type="text" formControlName="firstName" />                 
                </mat-form-field>
            </div>

            <div class="form-control flex-column">
                <mat-label>Administrator Last Name</mat-label>
                <mat-form-field appearance="outline">
                    <input matInput type="text" formControlName="lastName" />                    
                </mat-form-field>
            </div>
        </div>
    </ng-container>

    <div class="form-control">        
        <button type="submit" [disabled]="!clinicForm.valid" mat-flat-button color="primary">Save</button>        
    </div>
</form>