import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, distinctUntilChanged, map, merge, scan, shareReplay, startWith, takeUntil } from 'rxjs';
import { GixamDevicesSearchParams } from 'src/app/models/GixamDevicesSearchParams.model';
import { DeviceHubService } from 'src/app/services/signalR/device-hub.service';

@Injectable()
export class GixamDevicesTableService {

    private _refreshData$ = new Subject<void>();
    refreshData$ = this._refreshData$.asObservable();

    private pageSize$ = new BehaviorSubject<number>(10);
    private pageNumber$ = new BehaviorSubject<number>(0);
    private textFilter$ = new BehaviorSubject<string | null>(null);
    private sortColumn$ = new BehaviorSubject<any | null>(null);

    //private devicePingUpdated$ = new Subject<any>();
    // onDevicePingUpdated$ = this.devicePingUpdated$.asObservable().pipe(distinctUntilChanged(), startWith(null));
    
    private pageNumberSource$ = this.pageNumber$.pipe(map(pageNumber => ({ pageNumber }) as GixamDevicesSearchParams));
    private pageSizeSource$ = this.pageSize$.pipe(map(pageSize => ({ pageSize }) as GixamDevicesSearchParams));
    private textFilterSource$ = this.textFilter$.pipe(distinctUntilChanged(), map(filter => ({ freeText: filter }) as GixamDevicesSearchParams));
    private columnSortSource$ = this.sortColumn$.pipe(distinctUntilChanged(), map((filter:any) =>   ({sortColumn: filter?.sortColumn, sortDirection:filter?.sortDirection }) as GixamDevicesSearchParams ));
    
    private searchOptionsSources$: Observable<GixamDevicesSearchParams> = merge(
        this.textFilterSource$,
        this.pageNumberSource$,
        this.pageSizeSource$,
        this.columnSortSource$);

    // Search options state
    searchOptions$: Observable<GixamDevicesSearchParams> = this.searchOptionsSources$
        .pipe(
            scan((acc, value) => {
                // Whenever something changes, unless it's the page number, return to the first page
                const pageNumber = value.pageNumber === undefined ? 0 : value.pageNumber;
                return { ...acc, ...value, pageNumber } as GixamDevicesSearchParams;
            }, new GixamDevicesSearchParams()),
            shareReplay(1)
        );


    constructor() { }

    refresh() {
        this._refreshData$.next();
    }

    updateFilter(term: string) {
        this.textFilter$.next(term);
    }

    updatePageSize(pageSize: number) {
        this.pageSize$.next(pageSize);
    }

    updatePageNumber(page: number) {
        this.pageNumber$.next(page);
    }

    updateSortColumn(column:string, sortDirection:String){

        if(!sortDirection.length)
            this.sortColumn$.next({sortColumn:null, sortDirection:null})
        else
            this.sortColumn$.next({sortColumn:column, sortDirection:sortDirection})
    }    
}