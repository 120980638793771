import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GixamStudiesSearchParams } from '../models/GixamStudiesSearchParams.model';
import { environment } from 'src/environments/environment';
import { catchError, of, switchMap, throwError } from 'rxjs';
import { ApiTokenInterceptor } from '../intercaptors/api-token.interceptor';

@Injectable({
  providedIn: 'root'
})
export class GixamStudiesDataService {

  private _endpointBasePath$ = of(environment.apiUrl + 'api/studies');
  
  constructor(private httpClient: HttpClient) { }

  searchStudies(searchParams: GixamStudiesSearchParams){
    return this._endpointBasePath$.pipe(
      switchMap((endpointBasePath: string) => this.httpClient.post<any>(`${endpointBasePath}/search`, searchParams)),
      catchError(err => throwError(()=> new Error(err))));
  }
    
  getStudy(studyId:string){
    return this._endpointBasePath$.pipe(
      switchMap((endpointBasePath: string) => this.httpClient.get<any>(`${endpointBasePath}/${studyId}`)),
      catchError(err => throwError(()=> new Error(err))));
  }

  importStudies(){
    return this._endpointBasePath$.pipe(
      switchMap((endpointBasePath: string) => this.httpClient.get<any>(`${endpointBasePath}/import`)),
      catchError(err => throwError(()=> new Error(err))));
  }

  findStudy(studyId:string, studySite:string, userId:string){

    var qParams = new HttpParams();
    qParams = qParams.append("studyId",studyId);
    qParams = qParams.append("studySite",studySite);
    qParams = qParams.append("userId",userId);

    return this._endpointBasePath$.pipe(
      switchMap((endpointBasePath: string) => this.httpClient.get<any>(`${endpointBasePath}/find?studyId=${studyId}&studySite=${studySite}&userId=${userId}`,{params:qParams})),
      catchError(err => throwError(()=> new Error(err))));
  }

  getStudiesByPatient(patientNumber:string, excludeStudyNumber:string){

    var model = {
      PatientNumber : patientNumber,
      excludeStudyNumber: excludeStudyNumber
    }

    return this._endpointBasePath$.pipe(
      switchMap((endpointBasePath: string) => this.httpClient.post<any>(`${endpointBasePath}/getByPatient`,model)),
      catchError(err => throwError(()=> new Error(err))));
  }
}
