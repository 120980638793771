import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GixamEditStudyContactDetailsDialogComponent } from '../../studies/edit-study-contact-details-dialog/gixam-edit-study-contact-details-dialog.component';

@Component({
  selector: 'app-product-details-dialog',
  templateUrl: './gixam-product-physical-details-dialog.component.html',
  styleUrls: ['./gixam-product-physical-details-dialog.component.scss']
})
export class GixamProductDetailsDialogComponent {
  public productDetails: any;

  constructor(@Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<GixamEditStudyContactDetailsDialogComponent>) {
    this.productDetails = data.product; 
  }


}
