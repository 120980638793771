import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { BehaviorSubject, Observable, Subject, catchError, distinctUntilChanged, filter, finalize, map, merge, of, shareReplay, switchMap, takeUntil, tap, throwError } from "rxjs";
import { GixamStudiesDataService } from "src/app/services/gixam-exams-data.service";
import { GixamStudiesTableService } from "./gixam-studies-table.service";
import { NgxUiLoaderService } from "ngx-ui-loader";

export class GixamStudiesTableDataSource implements DataSource<any> {
  private destroy$ = new Subject<void>();

  private searchOptions$ = this.studiesTableService.searchOptions$;
  private refreshData$ = this.studiesTableService.refreshData$.pipe(tap(_ => this.studiesTableService.updatePageNumber(0)));

  private _totalRowsCount$ = new BehaviorSubject<number>(0);
  public totalRowsCount$ = this._totalRowsCount$.pipe(takeUntil(this.destroy$), distinctUntilChanged(), shareReplay(1));

  private loaderName = "studies-loader";
  constructor(private studiesDataService: GixamStudiesDataService, private studiesTableService: GixamStudiesTableService, private loaderService:NgxUiLoaderService) { }

  connect(collectionViewer: CollectionViewer): Observable<any> {   

    this.studiesTableService.updatePageSize(10);
    
    this.loaderService.startLoader(this.loaderName);
    return this.pageRows$.pipe(
      tap(() => this.loaderService.stopLoader(this.loaderName)),
      catchError((error) => {
        this.loaderService.stopLoader(this.loaderName);
        return throwError(() => new Error(error));
      }));
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.destroy$.next();
    this.destroy$.complete();    
  }

  public pageRows$ = merge(this.refreshData$, this.searchOptions$)
    .pipe(
      switchMap(() => this.searchOptions$),
      takeUntil(this.destroy$),
      filter(searchOptions => {
        return searchOptions !== null;
      }),
      tap(() => this.loaderService.startLoader(this.loaderName)),
      switchMap(searchOptions => this.searchStudies(searchOptions)),
      tap(response => {
        this._totalRowsCount$.next(response.results.count || 1);
      }),
      map(response => response.results.pagedStudies),
      tap(() => this.loaderService.stopLoader(this.loaderName)),
      shareReplay(1)
    );

  private searchStudies(searchParams: any) {
    return this.studiesDataService.searchStudies(searchParams)
      .pipe(catchError(error => {
        return of([]);
      }));

  }
}
