export enum GixamProductCategoryEnum{
    Physical_Product = 1,
    Digital_Product = 2
}

export namespace GixamProductCategoryEnum {

    
    export function toString(productCategory: GixamProductCategoryEnum): String {
        switch (productCategory) {
            case GixamProductCategoryEnum.Physical_Product:
                return 'Physical Product';
            case GixamProductCategoryEnum.Digital_Product:
                return 'Digital Product';
            default:
                return '';
        }
    }

    export function listAll() {
        return [
            GixamProductCategoryEnum.Physical_Product,
            GixamProductCategoryEnum.Digital_Product,            
        ]
    }

    export function parse(str: string) {
        return GixamProductCategoryEnum[str as keyof typeof GixamProductCategoryEnum];
    }
}

export default GixamProductCategoryEnum;