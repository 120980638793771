import { Component } from '@angular/core';
import { GixamShoppingCartService } from '../../../layout/headers/gixam-header/gixam-shopping-cart-dialog/gixam-shopping-cart.service';
import GixamProductCategoryEnum from 'src/app/models/enums/gixam-product-cateogry.enum';

@Component({
  selector: 'app-gixam-shopping-cart',
  templateUrl: './gixam-shopping-cart.component.html',
  styleUrls: ['./gixam-shopping-cart.component.scss'],
})
export class GixamShoppingCartComponent {
  cartItems: any[] = [];
  gixamProductCategoriesEnum = GixamProductCategoryEnum;

  totalPrice: number;

  constructor(private cartService: GixamShoppingCartService) { }

  ngOnInit() {
    this.cartService.cartItems$.subscribe(items => {
      this.cartItems = items;
    });
    this.cartService.totalPrice$.subscribe(items => {
      this.totalPrice = items;
    });  }

}
