import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AccountService } from 'src/app/account/account.service';
import { GixamShoppingCartService } from './gixam-shopping-cart-dialog/gixam-shopping-cart.service';

@Component({
  selector: 'gixam-header',
  templateUrl: './gixam-header.component.html',
  styleUrls: ['./gixam-header.component.scss']
})
export class GixamHeaderComponent {
  cartItems: any[] = [];
  userName:string | null = '';
  cartItemCount: number = 0;

  constructor(private accountService:AccountService, private router:Router,
    private cartService: GixamShoppingCartService
  ) {
    this.userName = this.accountService.userName;
  }

  ngOnInit() {
    this.cartService.cartItems$.subscribe((items:any) => {
      this.cartItemCount = this.cartService.getCartItemCount();
    });
  }

  isAuthenticated = ()=>{this.accountService.isUserAuthenticated();}

  logout = ()=>{ this.accountService.signout(); }

  orderDevices = ()=> {
    this.router.navigate(['gixam/orders/order']);
  }

  toProducts = () => {
    this.router.navigate(['gixam/products']);
  }

}
