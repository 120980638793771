import GixamColorsEnum from "./enums/gixam-colors.enum";
import GixamEntityStateEnum from "./enums/gixam-entity-state.enum";
import GixamOrderStatusEnum from "./enums/gixam-order-status.enum";
import GixamProductCategoryEnum from "./enums/gixam-product-cateogry.enum";
import { GixamCountryModel, GixamStateModel } from "./gixam-country.model";
import { GixamCurrencyModel } from "./gixam-currency.model";
import { GixamProduct } from "./gixam-product.model";

export class GixamOrder {
    id: string;
    orderDate: Date;
    orderNumber: string;
    transactionNumber?: string;
    orderStatus: GixamOrderStatusEnum;
    tax?: number;
    coupon?: string; // TO DO: replace string with new GixamCouponModel (need to create the model)
    currency: GixamCurrencyModel;
    shipment: GixamOrderShipmentDetails;

    lines: GixamOrderLine[] = [];

    locale: string = "en-us";

    constructor() {
        this.orderDate = new Date();

        this.lines = [];
    }
}

export class GixamOrderLine {
    id?: string;
    product: GixamProduct;
    color?: GixamColorsEnum;
    deviceId?: string;

    isSelected: boolean;
    state: GixamEntityStateEnum;

    constructor() {

    }
}

export class GixamCreateOrderApiRequest {
    currency: string;
    locale: string;
    shippingDetails?:GixamOrderShipmentDetails;    
    orderDetails?: GixamCreateOrderLineApiRequest[];
    ModelState: GixamEntityStateEnum = GixamEntityStateEnum.New;

    constructor(order?: GixamOrder) {

        if (order) {
            this.currency = order.currency!.id;
            this.shippingDetails = order.shipment;            
            this.locale = order.locale,
                this.orderDetails = order.lines?.length ? order.lines.map(element => new GixamCreateOrderLineApiRequest(element)) : [];
        }
    }
}

export class GixamCreateOrderLineApiRequest {
    product: string;
    color?: GixamColorsEnum;
    device?: string;

    constructor(orderLine: GixamOrderLine) {

        if (orderLine) {
            this.product = orderLine.product!.id;
            this.device = orderLine.deviceId;
            this.color = orderLine.color;
        }

    }
}

export class GixamUpdateOrderApiRequest {

    id: string;
    currencyId: string;
    locale: string;
    shippingDetails?:GixamOrderShipmentDetails;
    orderDetails: GixamUpdateOrderLineApiRequest[];
    ModelState: GixamEntityStateEnum;

    constructor(order: GixamOrder) {
        if (order) {
            this.currencyId = order.currency!.id;
            this.ModelState = GixamEntityStateEnum.Modified;

            this.locale = order.locale,
                this.orderDetails = order.lines?.length ? order.lines.map(element => new GixamUpdateOrderLineApiRequest(element)) : [];
        }
    }
}

export class GixamUpdateOrderLineApiRequest {

    id: string;
    productId: string;    
    color?: GixamColorsEnum;    
    deviceId?: string;
    ModelState: GixamEntityStateEnum;
    constructor(orderLine: GixamOrderLine) {

        if (orderLine) {
            this.productId = orderLine.product!.id;
            this.color = orderLine.color;
            this.deviceId = orderLine.deviceId;
            this.ModelState = orderLine.id ? GixamEntityStateEnum.New : GixamEntityStateEnum.Modified;
        }
    }
}


export class GixamOrderShipmentDetails {
    street: string;
    city: string;

    country?: GixamCountryModel;
    countryId: string;
    countryName?: string;

    state?: GixamStateModel;
    stateId?: string;
    stateName?: string;

    postalCode: string;

    contactName: string;
    contactEmail: string;
    contactPhoneNumber: string;

    shippingCost?: number;
    carrier?: GixamCarrierModel;
    ETA?: Date;
}

export class GixamAddressDetails {
    street: string;
    city: string;

    country?: GixamCountryModel;
    countryId: string;
    countryName?: string;

    state?: GixamStateModel;
    stateId?: string;
    stateName?: string;

    postalCode: string;
}

export class GixamPersonalDetails {
    contactName: string;
    contactEmail: string;
}

export class GixamPhoneDetails {
    contactPhoneNumber: string;
}




export class GixamCarrierModel {
    id: string;
    name: string;
}