import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { NotFoundComponent } from './not-found/not-found.component';

const routes: Routes = [
 

  // { path: 'site', loadChildren: ()=> import('./site/site.module').then(x=>x.SiteModule)},
  { path: 'account', loadChildren: ()=> import('./account/account.module').then(x=>x.AccountModule)},
  { path: 'gixam', loadChildren: ()=> import('./gixam/gixam.module').then(x=>x.GixamModule)},  
  { path: 'unauthorized', component:UnauthorizedComponent},
  { path: '', redirectTo:'gixam', pathMatch:'full' },
  { path: '**', component:NotFoundComponent}

];

@NgModule({
  imports: [RouterModule.forRoot(routes,{onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
