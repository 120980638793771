import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, ViewChild, inject } from '@angular/core';
import { StripeEmbeddedCheckout, StripeEmbeddedCheckoutOptions } from '@stripe/stripe-js';
import { StripeService, injectStripe } from 'ngx-stripe';
import { GixamStripeService } from './gixam-stripe.service';
import { Observable, map, switchMap, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GixamEditDeviceDialogComponent } from 'src/app/gixam/devices/device/gixam-device-details/gixam-edit-device-dialog/gixam-edit-device-dialog.component';
import { GixamDevicesOrderRequestModel } from 'src/app/models/GixamDevicesOrderRequest.model';

@Component({
  selector: 'app-gixam-stripe',
  templateUrl: './gixam-stripe.component.html',
  styleUrls: ['./gixam-stripe.component.scss'],
  providers: [GixamStripeService]
})
export class GixamStripeComponent implements OnDestroy, AfterViewInit {

  @ViewChild("stripeCheckoutElement", { read: ElementRef }) stripeCheckoutElementWrapper: ElementRef;

  checkoutElement: StripeEmbeddedCheckout;
  gixamOrder: GixamDevicesOrderRequestModel;

  stripeService = inject(StripeService);
  stripe = injectStripe(environment.stripeKey);

  isLoaded = false;

  checkoutOptions: StripeEmbeddedCheckoutOptions = {};
  checkoutSession$: Observable<any>;

  constructor(@Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<GixamEditDeviceDialogComponent>,
    private gixamStripService: GixamStripeService,
    private cdr: ChangeDetectorRef) { }

  ngAfterViewInit() {
    if (this.data && this.data.order) {
      this.gixamOrder = this.data.order;
      this.checkoutOptions.onComplete = this.handleCheckoutComplete;
      this.isLoaded = true;

      this.checkoutSession$ = this.gixamStripService.createCheckoutSession(this.gixamOrder)
        .pipe(tap((clientSecret) => this.checkoutOptions.clientSecret = clientSecret.client_secret),
          switchMap(async clientSecret => await this.stripe.getInstance()!.initEmbeddedCheckout(this.checkoutOptions)),
          map(stripeInstance => {

            if (!this.checkoutElement) {
              this.checkoutElement = stripeInstance;
            }
            this.checkoutElement?.mount(this.stripeCheckoutElementWrapper.nativeElement);

            return this.checkoutOptions.clientSecret;
          }
          ));

      this.cdr.detectChanges();
    }
  }

  ngOnDestroy(): void {
    this.checkoutElement?.destroy();
  }

  handleCheckoutComplete = () => {
    alert('Completed');
  }
}