import { Component, Input } from '@angular/core';

@Component({
  selector: 'gixam-loader',
  templateUrl: './gixam-loader.component.html',
  styleUrls: ['./gixam-loader.component.scss']
})
export class GixamLoaderComponent {
  @Input() centerToParent: boolean;
}
