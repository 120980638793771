    <form [formGroup]="shipmentAddressForm">
        <mat-hint class="sab-title" >Device Location</mat-hint>
        <div class="form-controls">
            <div class="form-control flex-column flex-full">
                <mat-label>{{'STREET' | translate}}</mat-label>
               
                <mat-form-field appearance="outline">
                    <input matInput type="text" formControlName="street" spellcheck="false" />
                    <mat-error *ngIf="shipmentAddressForm.get('street')?.errors?.['required']">Field
                        is required</mat-error>
                </mat-form-field>
            </div>

            <div class="form-control flex-column flex-half">
                <mat-label>{{'CITY' | translate}}</mat-label>
                <mat-form-field appearance="outline">
                    <input matInput type="text" formControlName="city" spellcheck="false" />
                    <mat-error *ngIf="shipmentAddressForm.get('street')?.hasError('required')">Field
                        is required</mat-error>
                </mat-form-field>
            </div>
        </div>

        <div class="form-controls">

            <div class="form-control flex-column flex-full">
                <mat-label>{{'COUNTRY' | translate}}</mat-label>
                <mat-form-field appearance="outline">
                    <mat-select formControlName="country" [compareWith]="countryCompare" #shippingAddressCountry>
                        <mat-option *ngFor="let country of (countries_list| async)"
                            [value]="country">{{country.name}}</mat-option>
                    </mat-select>
                    <button matSuffix mat-icon-button aria-label="Clear" color="default" class="ctrl-clear-button"
                        *ngIf="shipmentAddressForm.get('country')?.enabled && shipmentAddressForm.get('country')?.value"
                        type="button" (click)="clearField($event, shippingAddressCountry)">
                        <mat-icon class="material-icons material-icons-outlined">close</mat-icon>
                    </button>
                    <mat-error *ngIf="shipmentAddressForm.get('country')?.errors?.['required']">Field is
                        required</mat-error>
                </mat-form-field>
            </div>


            <div class="form-control flex-column flex-full">
                <mat-label>{{'STATE' | translate}}</mat-label>
                <mat-form-field appearance="outline">
                    <mat-select formControlName="state" [compareWith]="stateCompare">
                        <mat-option *ngFor="let state of (sa_states_list$ | async)"
                            [value]="state">{{state.name}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="shipmentAddressForm.get('state')?.errors?.['required']">Field
                        is required</mat-error>
                </mat-form-field>
            </div>

            <div class="form-control flex-column flex-half">
                <mat-label>{{'POSTAL_CODE' | translate}}</mat-label>
                <mat-form-field appearance="outline">
                    <input matInput type="text" formControlName="postalCode" spellcheck="false" />
                    <mat-error *ngIf="shipmentAddressForm.get('postalCode')?.errors?.['required']">Field is
                        required</mat-error>
                </mat-form-field>
            </div>

        </div>

    </form>
