<div class="checkid-wrapper">
    <div class="header">
        <mat-toolbar>       
            <div class="logo-wrapper">
                <img id="jubaan-logo" src="assets/images/Jubaan-logo.png" />
            </div>
        </mat-toolbar>        
    </div>
    <div class="content">
        <div class="content-inner" style="width: 80%;margin:0 auto">
            <ng-container *ngIf="(study$ | async) as study; else temp">
                <h1>Study: {{study.studyNumber}}</h1>                

                <h4>Study Date: {{study.studyDate | date}}</h4>
                
                <h4>Patient Id: {{study.patientNumber}}</h4>
                <h4>Patient Age: {{study.patientAge}}</h4>       
                <!-- <h4>Prediction Value: {{study.predictionValue }}%</h4>     -->
                <h4>Gender: {{study.gender}}</h4>
                <hr/>

                <div class="patient-studies" *ngIf="(patientStudies$ | async).length > 0">
                    <h3>Additional Studies for this Patient:</h3>
                    <div class="patient-study" *ngFor="let pStudy of (patientStudies$ | async)">
                        <span>{{pStudy.studyNumber}}</span>
                    </div>

                </div>
            </ng-container>
        </div>
        

        <ng-template #temp>
            <div class="content-inner">
                <h1>Study: {{(studyId$ | async)?.studyId}}</h1>
                <h4>Could not locate study</h4>
            </div>
        </ng-template>
        
    </div>    
</div>
