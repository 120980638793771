import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map, switchMap, tap } from 'rxjs';
import { GixamStudiesDataService } from 'src/app/services/gixam-exams-data.service';

@Component({
  selector: 'app-check-id',
  templateUrl: './check-id.component.html',
  styleUrls: ['./check-id.component.scss']
})
export class CheckIdComponent {

studyId$ = this.activatedRoute.paramMap.pipe(map(p => {

  return {
    studyId:p.get('studyId')!,
    site: p.get('studySite')!,
    user: p.get('userId')!
  }
  
}));



study$ = this.studyId$.pipe(switchMap(data=> this.studyService.findStudy(data.studyId, data.site,data.user)),map(data=>data.studyDetails))


patientStudies$ = this.study$
.pipe(switchMap(studyResult=> this.studyService.getStudiesByPatient(studyResult.patientNumber, studyResult.studyNumber)),map(result=>result.studies));

constructor(private activatedRoute:ActivatedRoute, private studyService:GixamStudiesDataService) {
  
}

}
