import { Injectable } from '@angular/core';
import { Subject, map, Observable, merge, scan, shareReplay, startWith, BehaviorSubject, distinctUntilChanged, switchMapTo, tap } from 'rxjs';
import { GixamStudiesSearchParams } from 'src/app/models/GixamStudiesSearchParams.model';
import { GixamStudiesDataService } from 'src/app/services/gixam-exams-data.service';

@Injectable()
export class GixamStudiesTableService {

    private _refreshData$ = new Subject<void>();
    refreshData$ = this._refreshData$.asObservable();

    private pageSize$ = new Subject<number>();
    private pageNumber$ = new Subject<number>();
    private filterParams$ = new Subject<GixamStudiesSearchParams>();
    private sortColumn$ = new Subject<any | null>();

    private pageNumberSource$ = this.pageNumber$.asObservable().pipe(distinctUntilChanged(),map(pageNumber => ({ pageNumber }) as GixamStudiesSearchParams));
    private pageSizeSource$ = this.pageSize$.asObservable().pipe(startWith(10),distinctUntilChanged(),map(pageSize => ({ pageSize }) as GixamStudiesSearchParams));
    private filterParamsSource$ = this.filterParams$.asObservable();
    private columnSortSource$ = this.sortColumn$.pipe(distinctUntilChanged(), map((filter: any) => ({ sortColumn: filter?.sortColumn, sortDirection: filter?.sortDirection }) as GixamStudiesSearchParams));

    private searchOptionsSources$: Observable<GixamStudiesSearchParams> = merge(
        this.filterParamsSource$,
        this.pageNumberSource$,
        this.pageSizeSource$,
        this.columnSortSource$);

    // Search options state
    searchOptions$: Observable<GixamStudiesSearchParams> = this.searchOptionsSources$
        .pipe(
            scan((acc, value) => {
                // Whenever something changes, unless it's the page number, return to the first page
                const pageNumber = value.pageNumber === undefined ? 0 : value.pageNumber;
                const pageSize = value.pageSize === undefined ? 10 : value.pageSize;

                return { ...acc, ...value, pageNumber,pageSize } as GixamStudiesSearchParams;
            }, new GixamStudiesSearchParams()),
            shareReplay(1)
        );


    constructor(private studyDataService: GixamStudiesDataService) { }

    refresh() {
        this._refreshData$.next();
    }

    updateFilter(filter: GixamStudiesSearchParams) {
        this.filterParams$.next(filter);
    }

    updatePageSize(pageSize: number) {        
        this.pageSize$.next(pageSize);
    }

    updatePageNumber(page: number) {
        this.pageNumber$.next(page);
    }

    updateSortColumn(column:string, sortDirection:String){

        if(!sortDirection.length)
            this.sortColumn$.next({sortColumn:null, sortDirection:null})
        else
            this.sortColumn$.next({sortColumn:column, sortDirection:sortDirection})
    }

    getStudyVideo(studyId:string){

        return this.studyDataService.getStudyVideoUrl(studyId);        
    }
}
