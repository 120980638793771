<div class="MainPysicalCart">
    <img class="itemImage" src={{item?.image}} />
    <div class="textDetails">
        <div style="font-weight: bold;">{{item?.name}}</div>
        <div class="font-gray">{{item?.description}}</div>
    </div>
    <div>x{{item?.amount}}</div>
    <div>200$</div>
    <button (click)="removeItem()" mat-icon-button type="button">

        <mat-icon style="color: rgb(207, 24, 24);">delete</mat-icon>
    </button>


</div>