import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { GixamProductsDataService } from 'src/app/services/gixam-products-data.service';
import { GixamProductsListService } from './gixam-products-list.service';
import { GixamProductsListDataSource } from './gixam-products-list-data-source';
import { Observable, map, tap } from 'rxjs';
import GixamProductCategoryEnum from 'src/app/models/enums/gixam-product-cateogry.enum';

@Component({
  selector: 'gixam-products-list',
  templateUrl: './gixam-products-list.component.html',
  styleUrls: ['./gixam-products-list.component.scss']
})
export class GixamProductsListComponent {
  virtualProducts$: Observable<any[]>;

  productsListDataSource = new GixamProductsListDataSource(this.productsDataService, this.productsListService, this.loaderService);

  products$ = this.productsListDataSource.products$.pipe(
    map(products =>
      products.sort((a, b) => {
        const categoryA = a.productCategory != null ? String(a.productCategory) : ''; 
        const categoryB = b.productCategory != null ? String(b.productCategory) : ''; 
        return categoryA.localeCompare(categoryB);
      })
    )
  );

  physicalProducts$ = this.products$.pipe(
    map((products: any[]) => products.filter(product => product.productCategory === GixamProductCategoryEnum.Physical_Product))
  );
  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private loaderService: NgxUiLoaderService,
    private productsDataService: GixamProductsDataService,
    private productsListService: GixamProductsListService,
  ) {
    
    // Extract virtual products
    this.virtualProducts$ = this.products$.pipe(
      map((products: any[]) => products.filter(product => product.productCategory === GixamProductCategoryEnum.Digital_Product))
    );
  }


  goToProduct(product: any) {    
    this.router.navigate(['../orderProduct/' + product.id],
      {
        relativeTo: this.activatedRoute,
        state: { product: product }
      });
  }
}
