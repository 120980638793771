import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError, map, of, shareReplay, switchMap, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GixamFilesService {

  private endpointPath$ = of(environment.apiUrl + 'api/import');
  private studiesEndpointPath$ = of(environment.apiUrl + 'api/studies');
  constructor(private http: HttpClient) { }

  private upload(model: any) {  
    
    return this.endpointPath$.pipe(
      switchMap(endpointBase => this.http.post<any>(`${endpointBase}/import`, model,{reportProgress:true, observe:'events'})),      
      catchError(err => throwError(() => new Error(err))));   
  }

  uploadZipFile(model: any) {    
    return this.studiesEndpointPath$.pipe(
      switchMap(studiesEndpointBase => this.http.post<any>(`${studiesEndpointBase}/import`, model,{reportProgress:true, observe:'events'})),      
      catchError(err => throwError(() => new Error(err)))); 
  }

  syncWithStorage(deviceId:string) {    
    return this.studiesEndpointPath$.pipe(
      switchMap(studiesEndpointBase => this.http.get<any>(`${studiesEndpointBase}/${deviceId}/sync`)),
      catchError(err => throwError(() => new Error(err)))); 
  }

  downloadFile(model:any){

  }

  formatFileSize(fileSize:number, decimals=2){
    // if(fileSize <= 0) return "0";
    // let units = [ "B", "kB", "MB", "GB", "TB" ];
    // let digitGroups = parseInt((Math.log10(fileSize) / Math.log10(1024)).toString());
    // return (fileSize/Math.pow(1024, digitGroups)) + " " + units[digitGroups];

    if (!+fileSize) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']

    const i = Math.floor(Math.log(fileSize) / Math.log(k))

    return `${parseFloat((fileSize / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
  }

}
