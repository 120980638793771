<div class="dialog-wrapper">
    <div class="dialog-title">
        <button class="close-button" [mat-dialog-close]="false" type="button" tabindex="-1" mat-icon-button
            aria-label="Close">
            <mat-icon class="material-icons notranslate">clear</mat-icon>
        </button>
        <h3>{{'PAYMENT' | translate}}</h3>
    </div>
    <div class="dialog-content" *ngIf="isLoaded">
        <ngx-ui-loader [loaderId]="'stripe-loader'" [hasProgressBar]="false"></ngx-ui-loader>
        <ng-container *ngIf="(checkoutSession$ | async)"></ng-container>
        <div #stripeCheckoutElement></div>
    </div>    
</div>