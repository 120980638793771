import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, firstValueFrom, from, lastValueFrom, of, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AccountService } from '../account/account.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiTokenInterceptor implements HttpInterceptor {

  constructor(private router: Router, private accountService: AccountService) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (request.url.startsWith(environment.apiUrl)) {

      let auth_header = "";

      auth_header = this.accountService.getAuthorizationHeaderValue();

      if (auth_header) {
        request = request.clone({
          setHeaders: {
            "Authorization": auth_header
          }
        });
      }

      request = request.clone({
        setHeaders:{
          client:environment.clientId
        }
      });    

      let clinicId =  this.accountService.currentClinic || '';

      if (clinicId){
        request = request.clone({
          setHeaders:{
            "gx-request-clinic":clinicId,
          }
        });    
      }



      // if (this.accountService.isUserAuthenticated()) {

      //       }
      //       request = request.clone({
      //           setHeaders: {
      //               "Authorization": auth_header
      //           }
      //       });
    }

    return next.handle(request);
  }

  private handleError = (error: HttpErrorResponse): string => {

    if (error.status === 404) {
      return this.handleNotFound(error);
    }

    else if (error.status === 400) {
      return this.handleBadRequest(error);
    }

    return "";
  }

  private handleNotFound = (error: HttpErrorResponse): string => {
    this.router.navigate(['/404']);
    return error.message;
  }

  private handleBadRequest = (error: HttpErrorResponse): string => {
    let message = '';
    const values = Object.values(error.error.errors);

    values.map(m => {
      message += m + '<br/>';
    })

    return message.slice(0, -4);
  }

  getToken() {
    return of(this.accountService?.getAccessToken());
  }
}