import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, of, switchMap, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GixamPatientUpdateModel } from '../models/gixam-patient.model';

@Injectable({
  providedIn: 'root'
})
export class GixamPatientsDataService {

  constructor(private httpClient:HttpClient) { }
  private _endpointBasePath$ = of(environment.apiUrl + 'api/patients');

  getPatientById(patientId:string){
    return this._endpointBasePath$.pipe(
      switchMap((endpointBasePath: string) => this.httpClient.get<boolean>(`${endpointBasePath}/${patientId}`)),
      catchError(err => throwError(()=> new Error(err))));
  }

  searchPatients(patientSearchParams:any){
    return this._endpointBasePath$.pipe(
      switchMap((endpointBasePath: string) => this.httpClient.post<boolean>(`${endpointBasePath}/search`, patientSearchParams)),
      catchError(err => throwError(()=> new Error(err))));
  }

  updatePatient(patient:GixamPatientUpdateModel){
    return this._endpointBasePath$.pipe(
      switchMap((endpointBasePath: string) => this.httpClient.post<boolean>(`${endpointBasePath}/update`, patient)),
      catchError(err => throwError(()=> new Error(err))));
  }
}
