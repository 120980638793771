export enum GixamEntityStateEnum{
    Unchanged = 1,
    New = 2,
    Modified = 3,
    Removed = 4
}

export namespace GixamEntityStateEnum{
    export function toString(gxmGender:GixamEntityStateEnum){
        switch(gxmGender){
            case GixamEntityStateEnum.Unchanged:
                return "Unchanged";
            case GixamEntityStateEnum.New:
                return "New";
            case GixamEntityStateEnum.Modified:
                return "Modified";
                case GixamEntityStateEnum.Removed:
                return "Removed";
            default : return '';
        }
    }

    export function listAll(){
        return [
            GixamEntityStateEnum.Unchanged,
            GixamEntityStateEnum.New,
            GixamEntityStateEnum.Modified,
            GixamEntityStateEnum.Removed
        ]
    }

    export function parse(str:string){
        return GixamEntityStateEnum[str as keyof typeof GixamEntityStateEnum]
    }
}

export default GixamEntityStateEnum;