<ng-container *ngIf="{
  searchOptions: searchOptions$ | async,
  pageRowsCount: pageRowsCount$ | async,
  totalRowsCount : totalRowsCount$ | async,
  isOnline: isOnline$ | async  } as data">
  <div style="position: relative;">
    <mat-table [dataSource]="devicesTableDataSource" matSort (matSortChange)="deviceSortChanged($event)">
      
      <ng-container matColumnDef="deviceId">
        <mat-header-cell *matHeaderCellDef>{{'DEVICE_ID'| translate}}</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.id}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="deviceName">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{'DEVICE_NAME' | translate}}</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <div style="display: flex;flex-direction: row;gap:1rem;align-items:center">
            <div class="online-indicator" [ngClass]="element.isDeviceOnline ? 'online':'offline'" matTooltip="{{'Last Sync: ' + (element.lastPing | date: 'MMM dd, yyyy - HH:mm')}}" [matTooltipDisabled]="!element.lastPing"></div>
            <div class="cell-inner" style="display: flex;flex-direction: column;">
              <span>{{element.alias}}</span>
              <span style="font-size: .75rem;color:#6F7F84;font-style: italic;">{{element.serial}}</span>
            </div>
          </div>          
         </mat-cell>
      </ng-container>      

      <ng-container matColumnDef="location">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{'LOCATION' | translate}}</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.location}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="state">
        <mat-header-cell *matHeaderCellDef>{{'STATE' | translate}}</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <mat-chip-listbox *ngIf="element.state">
            <mat-chip class="not-active">
              {{deviceStatesEnum.toString(element.state)}}
            </mat-chip>
          </mat-chip-listbox>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef>{{'STATUS' | translate}}</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <mat-chip-listbox *ngIf="element.status">
            <mat-chip class="not-active">
              {{deviceStatusesEnum.toString(element.status)}}
            </mat-chip>
          </mat-chip-listbox>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="recharge">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell class="mat-cell-last mat-cell-button" *matCellDef="let element">
          <button mat-stroked-button color="primary" class="button-medium"
            (click)="$event.stopPropagation(); rechargeOrder(element.id)">           
            {{'RECHARGE' | translate}}
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="goToDevice(row.id)"></mat-row>

      <tr style="width: 100%;display: flex;" *matNoDataRow>
        <td class="mat-cell" colspan="6" style="margin-top:2rem; border:1px solid #e9e9e9;border-radius:7px;height: 200px; padding: 1rem;display: flex;flex-direction: row;align-items: center;justify-content: center;width: 100%;font-size: 1.5rem;">
          {{'EMPTY_TABLE_RESULTS_NO_{DEVICES}_FOUND.' | translate}}
        </td>              
    </mat-table>
    <ngx-ui-loader [loaderId]="'devices-loader'" [hasProgressBar]="false"></ngx-ui-loader>
    <mat-paginator (page)="pageChange($event)" [length]="data?.totalRowsCount"
                 [pageSize]="data?.searchOptions?.pageSize"
                 [pageIndex]="data?.searchOptions?.pageNumber" *ngIf="data?.totalRowsCount! > 0">
  </mat-paginator>
  </div>
</ng-container>