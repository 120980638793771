import {  Component, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GixamPersonalDetails } from 'src/app/models/gixam-order.model';

@Component({
  selector: 'personal-details-form',
  templateUrl: './gixam-personal-details-form.component.html',
  styleUrls: ['./gixam-personal-details-form.component.scss'],
  providers: [GixamPersonalDetails]
})
export class PersonalDetailsFormComponent {

  @Output() shipmentDetailsChange = new EventEmitter<FormGroup>();

  personalDetaildForm = new FormGroup({
    contactName: new FormControl<string | null>(null, [Validators.required]),
    contactEmail: new FormControl<string | null>(null, [Validators.required, Validators.email]),
  });

  constructor() {}

  clearField(event: MouseEvent, element: any) {

    event.stopPropagation();

    if (element && element.ngControl) {
      element.ngControl.control.patchValue(null);
    }

  }

  isFormValid(): boolean {    
    return this.personalDetaildForm.valid;
  }

  getFormValue(): any {
    return this.personalDetaildForm.value;
  }

}
