import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer/footer.component';
import { ContentComponent } from './content/content.component';
import { SharedMaterialModule } from '../shared/modules/shared-material/shared-material.module';
import { RouterModule } from '@angular/router';
import { JubaanHeaderComponent } from './headers/jubaan-header/jubaan-header.component';
import { GixamHeaderComponent } from './headers/gixam-header/gixam-header.component';
import { TranslateModule } from '@ngx-translate/core';
import { GixamShoppingCartDialogModule } from './headers/gixam-header/gixam-shopping-cart-dialog/gixam-shopping-cart-dialog.module';
import { SharedModule } from "../shared/modules/shared/shared.module";



@NgModule({
  declarations: [
    JubaanHeaderComponent,
    GixamHeaderComponent,
    FooterComponent,
    ContentComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule.forChild({ extend: true }),
    SharedMaterialModule,
    GixamShoppingCartDialogModule,
    SharedModule
],
  exports:[
    JubaanHeaderComponent,
    GixamHeaderComponent,
    FooterComponent
  ]
})
export class LayoutModule { }
